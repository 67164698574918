import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconLeisure: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fill={variableColor}
        fillRule="evenodd"
        d="M11 3.25c-.4142 0-.75.33579-.75.75v1.25H8c-.41421 0-.75.33579-.75.75v4c0 .4142.33579.75.75.75h2.25v1.5H6.6c-.16541 0-.32618.0547-.45728.1555l-2.6 2c-.18457.142-.29272.3616-.29272.5945 0 .2329.10815.4525.29272.5945l2.6 2c.1311.1008.29187.1555.45728.1555h3.65v1.5H7c-.41421 0-.75.3358-.75.75s.33579.75.75.75h10.5c.4142 0 .75-.3358.75-.75s-.3358-.75-.75-.75h-2.75v-1.5H17c.4142 0 .75-.3358.75-.75v-4c0-.4142-.3358-.75-.75-.75h-2.25v-1.5h2.85c.1755 0 .3454-.0615.4801-.1738l2.4-2.00003c.171-.1425.2699-.35359.2699-.57617 0-.22258-.0989-.43367-.2699-.57617l-2.4-2C17.9454 5.31151 17.7755 5.25 17.6 5.25h-2.85V4c0-.41421-.3358-.75-.75-.75h-3Zm2.25 16v-1.5h-1.5v1.5h1.5Zm0-14v-.5h-1.5v.5h1.5Zm0 4h-4.5v-2.5h8.5785l1.5 1.25-1.5 1.25H13.25Zm-1.5 1.5h1.5v1.5h-1.5v-1.5Zm-4.89491 5.5H16.25v-2.5H6.85509L5.23009 15l1.625 1.25Z"
        clipRule="evenodd"
      />
    </BaseIcon>
  )
}

export default IconLeisure
