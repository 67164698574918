import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconAim: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fill={variableColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3a1 1 0 0 0-1 1v1.07A7.005 7.005 0 0 0 5.07 11H4a1 1 0 1 0 0 2h1.07A7.004 7.004 0 0 0 11 18.93V20a1 1 0 1 0 2 0v-1.07A7.004 7.004 0 0 0 18.93 13H20a1 1 0 1 0 0-2h-1.07A7.004 7.004 0 0 0 13 5.07V4a1 1 0 0 0-1-1Zm0 4a5 5 0 1 1 0 10 5 5 0 0 1 0-10Zm1 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm2 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
      />
    </BaseIcon>
  )
}

export default IconAim
