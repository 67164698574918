import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconArrowRight: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        d="M13.2 19.4001C12.8686 19.8419 12.9582 20.4687 13.4 20.8001C13.8418 21.1314 14.4686 21.0419 14.8 20.6001L20.8 12.6001C21.0667 12.2445 21.0667 11.7556 20.8 11.4001L14.8 3.40006C14.4686 2.95823 13.8418 2.86869 13.4 3.20006C12.9582 3.53143 12.8686 4.15823 13.2 4.60006L18 11.0001L4 11.0001C3.44772 11.0001 3 11.4478 3 12.0001C3 12.5523 3.44772 13.0001 4 13.0001L18 13.0001L13.2 19.4001Z"
        fill={variableColor}
      />
    </BaseIcon>
  )
}

export default IconArrowRight
