import { gql } from '@apollo/client'

export default gql`
  query userProfile {
    userProfile {
      id
      name
      email
      phone
      role
      type
      cpf
      creci
      completedAboutYou
      hasSearchPreferences
      requestedService
      paymentType
      hasApprovedCredit
      listingAnnounced
      incomeMonthly
      creditTotalValue
      buyerIntent {
        mainInterest
      }
      searchPreferences {
        city
        citySlug
        maxValue
        neighborhoods
        state
        stateSlug
      }
    }
  }
`
