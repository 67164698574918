import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconDoc: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fill={variableColor}
        fillRule="evenodd"
        d="m18.7853 6.96214-4.0192-3.51678C14.438 3.15825 14.0168 3 13.5808 3H6.8C5.80589 3 5 3.80589 5 4.8v14.4c0 .9941.80589 1.8 1.8 1.8h10.8c.9941 0 1.8-.8059 1.8-1.8V8.31678c0-.51905-.2241-1.01284-.6147-1.35464ZM13.1 4.8H6.8v14.4h10.8V9.3h-4.5V4.8Z"
        clipRule="evenodd"
      />
    </BaseIcon>
  )
}

export default IconDoc
