import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconStripDash: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fill={variableColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.9 7a.9.9 0 1 0 0 1.8h2.7a.9.9 0 1 0 0-1.8H3.9Zm6.464 0c-.588 0-1.064.403-1.064.9s.476.9 1.064.9h9.572c.588 0 1.064-.403 1.064-.9s-.476-.9-1.064-.9h-9.572Zm0 4.5c-.588 0-1.064.403-1.064.9s.476.9 1.064.9h9.572c.588 0 1.064-.403 1.064-.9s-.476-.9-1.064-.9h-9.572ZM9.3 16.9c0-.497.476-.9 1.064-.9h9.572c.588 0 1.064.403 1.064.9s-.476.9-1.064.9h-9.572c-.588 0-1.064-.403-1.064-.9ZM3 12.4a.9.9 0 0 1 .9-.9h2.7a.9.9 0 1 1 0 1.8H3.9a.9.9 0 0 1-.9-.9Zm.9 3.6a.9.9 0 1 0 0 1.8h2.7a.9.9 0 1 0 0-1.8H3.9Z"
      />
    </BaseIcon>
  )
}

export default IconStripDash
