import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconHeart: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        d="M11.2386 6.52934L12 7.4234L12.7614 6.52934C13.5588 5.59294 14.7868 5 16.05 5C18.2697 5 20 6.73029 20 8.95C20 10.2484 19.1478 11.7612 17.494 13.6032C16.1366 15.115 14.3843 16.6974 12.4777 18.419C12.3193 18.5621 12.1598 18.7061 11.9993 18.8512C11.857 18.7227 11.7154 18.5951 11.5746 18.4681C9.64801 16.731 7.87572 15.1331 6.50617 13.6068C4.85256 11.7639 4 10.2487 4 8.95C4 6.73028 5.73028 5 7.95 5C9.21321 5 10.4412 5.59294 11.2386 6.52934Z"
        stroke={variableColor}
        strokeWidth="2"
        fill="none"
      />
    </BaseIcon>
  )
}

export default IconHeart
