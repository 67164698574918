import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconFilter: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 7a2 2 0 1 1 4 0 2 2 0 0 1-4 0ZM3 7a4 4 0 0 1 7.874-1h9.001a1.125 1.125 0 0 1 0 2.25h-9.074A4.002 4.002 0 0 1 3 7Zm16.398 10a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm2 0a4 4 0 0 1-7.8 1.25H4.125a1.125 1.125 0 0 1 0-2.25h9.4a4.002 4.002 0 0 1 7.873 1Z"
        fill={variableColor}
      />
    </BaseIcon>
  )
}

export default IconFilter
