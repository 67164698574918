const WP = typeof window === 'undefined' ? null : window.performance

export interface INavigationTiming {
  fetchTime?: number
  workerTime?: number
  totalTime?: number
  downloadTime?: number
  timeToFirstByte?: number
  headerSize?: number
  dnsLookupTime?: number
  redirectTime?: number
}

const isPerformanceSupported = () =>
  WP !== null &&
  typeof WP.getEntriesByType === 'function' &&
  typeof WP.now === 'function' &&
  typeof WP.mark === 'function'
/**
 * Navigation Timing API provides performance metrics for HTML documents.
 * w3c.github.io/navigation-timing/
 * developers.google.com/web/fundamentals/performance/navigation-and-resource-timing
 */
export const getNavigationTiming = (): INavigationTiming => {
  if (!isPerformanceSupported()) {
    return {}
  }
  // There is an open issue to type correctly getEntriesByType
  // github.com/microsoft/TypeScript/issues/33866
  const navigation = WP!.getEntriesByType(
    'navigation',
  )[0] as PerformanceResourceTiming
  // In Safari version 11.2 Navigation Timing isn't supported yet
  if (!navigation) {
    return {}
  }
  const { responseStart } = navigation
  const { responseEnd } = navigation
  return {
    // fetchStart marks when the browser starts to fetch a resource
    // responseEnd is when the last byte of the response arrives
    // Cache seek plus response time
    fetchTime: responseEnd - navigation.fetchStart,
    // Request plus response time (network only)
    totalTime: responseEnd - navigation.requestStart,
    // Response time only (download)
    downloadTime: responseEnd - responseStart,
    // Time to First Byte (TTFB) - The amount of time it takes after the client sends an HTTP GET request
    // to receive the first byte of the requested resource from the server.
    // It is the largest web page load time component taking 40 to 60% of total web page latency.
    timeToFirstByte: responseStart - navigation.requestStart,
    // HTTP header size
    headerSize: navigation.transferSize - navigation.encodedBodySize || 0,
    // Measuring DNS lookup time - When a user requests a URL, the Domain Name System (DNS) is queried to translate a domain to an IP address.
    dnsLookupTime: navigation.domainLookupEnd - navigation.domainLookupStart,
    // redirects could add latency to requests
    redirectTime: navigation.redirectEnd - navigation.redirectStart,
  }
}
