export type TOptionsFormat = {
  day?: '2-digit' | 'numeric'
  weekday?: 'short' | 'long' | 'narrow'
}

export type TGetDateProperties = {
  date: string
  time: string
  day: string
  month: string
  year: string
  hour: string
  min: string
  weekDay: string
  weekFullDay: string
}

export const getDate = (dateString: string, timeString?: string) =>
  new Date(`${dateString}T${timeString || '00:00:00'}`)

export const getDayOfWeek = (date: Date, options?: TOptionsFormat) =>
  new Intl.DateTimeFormat('pt-BR', options).format(date)

export const getDateToBRL = (date: Date) =>
  date?.toLocaleString('pt-BR')?.replace(',', '')

export const getDateToISO = (date: Date) =>
  new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString()

export const getDateTimeProperties = (dateTime: Date): TGetDateProperties => {
  const dateTimeString = getDateToBRL(dateTime)
  const [date, time] = dateTimeString.split(' ')
  const [day, month, year] = date ? date.split('/') : []
  const [hour, min] = time ? time.split(':') : []
  const weekDay = dateTime && getDayOfWeek(dateTime, { weekday: 'short' })
  const weekFullDay = dateTime && getDayOfWeek(dateTime, { weekday: 'long' })

  return {
    date: date || '',
    time: time || '',
    day: day || '',
    month: month || '',
    year: year || '',
    hour: hour || '',
    min: min || '',
    weekDay: weekDay || '',
    weekFullDay: weekFullDay || '',
  }
}

export const getFutureDateFromDays = (days: number) => {
  const currentDate = new Date()
  const futureDate = new Date(
    currentDate.getTime() + days * 24 * 60 * 60 * 1000,
  )

  return futureDate
}
