import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconClock: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM12 19.2C15.9764 19.2 19.2 15.9764 19.2 12C19.2 8.02355 15.9764 4.8 12 4.8C8.02355 4.8 4.8 8.02355 4.8 12C4.8 15.9764 8.02355 19.2 12 19.2ZM12 6.6C12.4971 6.6 12.9 7.00294 12.9 7.5V11.4441L16.0023 12.9952C16.4469 13.2175 16.6271 13.7581 16.4048 14.2027C16.1825 14.6473 15.6419 14.8275 15.1973 14.6052L11.5973 12.8052C11.2735 12.6433 11.0899 12.3125 11.1 11.9729V7.5C11.1 7.00294 11.5029 6.6 12 6.6Z"
        fill={variableColor}
      />
    </BaseIcon>
  )
}

export default IconClock
