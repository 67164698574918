import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconBuildingHeight: FC<TIconProps> = ({
  color,
  width,
  height,
  title,
}) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fill={variableColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9613 5c-.5522 0-1 .44772-1 1v12.9234h1.9863V16.843h1.9904v2.0804h1.9899v2h-7.9666V6c0-1.65685 1.3432-3 3-3h4.9666v2h-4.9666Zm2.9767 2.23295h-1.9904v1.99043h1.9904V7.23295Zm0 3.19555h-1.9904v1.9905h1.9904v-1.9905Zm-1.9904 3.2189h1.9904v1.9905h-1.9904v-1.9905ZM9.92268 7.57586c.39052.39053 1.02372.39053 1.41422 0 .3905-.39052.3905-1.02369 0-1.41421L9.92945 4.7542 8.522 3.34676c-.39052-.39053-1.02369-.39053-1.41421 0L5.70034 4.7542 4.29289 6.16165c-.39052.39052-.39052 1.02369 0 1.41421.39053.39053 1.02369.39053 1.41422 0l1.32698-1.32698V17.8134L5.70771 16.487c-.39053-.3905-1.02369-.3905-1.41422 0-.39052.3905-.39052 1.0237 0 1.4142l1.40745 1.4075 1.40745 1.4074c.39052.3905 1.02369.3905 1.41421 0l1.40745-1.4074 1.40745-1.4075c.3905-.3905.3905-1.0237 0-1.4142-.3905-.3905-1.0237-.3905-1.41422 0l-1.38919 1.3892V6.18728l1.38859 1.38858Z"
      />
    </BaseIcon>
  )
}

export default IconBuildingHeight
