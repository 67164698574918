import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconTrash: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fill={variableColor}
        d="M9 9.5c0-.55228.44772-1 1-1 .5523 0 1 .44772 1 1v6c0 .5523-.4477 1-1 1-.55228 0-1-.4477-1-1v-6Zm4 0c0-.55228.4477-1 1-1s1 .44772 1 1v6c0 .5523-.4477 1-1 1s-1-.4477-1-1v-6Z"
      />
      <path
        fill={variableColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.84629 3h4.30741c.5111 0 .9708.31119 1.1606.78576.096.23996.1123.48607.0652.71424H20c.5523 0 1 .44772 1 1s-.4477 1-1 1h-1v12c0 1.1046-.8954 2-2 2H7c-1.10457 0-2-.8954-2-2v-12H4c-.55228 0-1-.44772-1-1s.44772-1 1-1h4.62052c-.04709-.22817-.03081-.47428.06518-.71424C8.87552 3.31119 9.33516 3 9.84629 3ZM7 6.5h10v12H7v-12Z"
      />
    </BaseIcon>
  )
}

export default IconTrash
