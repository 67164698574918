import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconImage: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fill={variableColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 5h14v6.5272l-3.1429-2.86001-5.1428 5.85001-3.2143-2.34-2.5 1.3V5Zm-2 9.5172V5c0-1.10457.89543-2 2-2h14c1.1046 0 2 .89543 2 2v14.1972h-.0096C20.8914 20.2092 20.038 21 19 21H5c-1.03803 0-1.89136-.7908-1.9904-1.8028H3v-4.68Zm9-5.2622c0 .9693-.7555 1.755-1.6875 1.755-.93198 0-1.6875-.7857-1.6875-1.755 0-.96926.75552-1.755 1.6875-1.755.932 0 1.6875.78574 1.6875 1.755Z"
      />
    </BaseIcon>
  )
}

export default IconImage
