import { FC } from 'react'
import {
  CLOUDINARY_IMAGE_URL,
  CLOUDINARY_IMAGE_URL_WITH_WATERMARK_OVERLAY,
} from 'config'
import {
  IconMoneySign,
  IconSubway,
  IconShine,
  IconArrowDown,
  IconBed,
  IconArea,
  IconCar,
  IconShower,
  IconElevator,
  IconBuilding,
  IconBuildingCircleMoneySign,
  IconBuildingHeight,
  IconConcierge,
  IconCondominium,
  IconHouseCircleMoneySign,
  IconImage,
  IconSun,
} from 'icons'
import { TIconProps } from 'icons/types'
import {
  GarageType,
  OrientationType,
  SunPeriodType,
  ListingQuery,
  Listing,
  Image,
  Tag,
} from 'graphql/types'
import { SUBWAY_DISTANCE } from 'lib/search'
import { TProps as TLabelProps } from 'components/Label'
import omit from 'lodash/omit'
import { minTwoDigits, toCurrency } from './number'
import { pluralize } from './text'
import {
  getAmortization,
  getBalance,
  getFirstPayment,
  DEFAULT_TERM,
  MINIMUM_ENTRY_PERCENTAGE,
  getLastPayment,
} from './financing'

type TListingSocialProof = {
  title: string
  text?: string
  labelVariant?: TLabelProps['variant']
  Icon?: TLabelProps['Icon']
}

const EMPTY_PRICE_PLACEHOLDER = 'R$ --'

export const listingSocialProof: Map<string, TListingSocialProof> = new Map([
  [
    'blacknovember',
    {
      title: 'Black November',
      text: 'Imóvel na Black November',
      labelVariant: 'dark',
    },
  ],
  [
    'development',
    {
      title: 'Lançamento',
      text: 'Prédio novo. Seja o primeiro proprietário',
      labelVariant: 'yellow',
      Icon: { Component: IconMoneySign, title: 'Ícone: Lançamento' },
    },
  ],
  [
    'newListing',
    {
      title: 'Novo no site',
      text: 'Imóvel publicado recentemente no site',
      labelVariant: 'red',
      Icon: { Component: IconShine, title: 'Ícone: Novo no site' },
    },
  ],
  [
    'priceRecentlyReduced',
    {
      title: 'Preço baixou',
      text: 'Imóvel abaixou de preço recentemente',
      labelVariant: 'green',
      Icon: { Component: IconArrowDown, title: 'Ícone: Preço baixou' },
    },
  ],
  [
    'normalizedLiquidityRatio',
    {
      title: 'Ótimo preço',
      text: 'Imóvel anunciado próximo ou abaixo do avaliado pela EmCasa',
      labelVariant: 'green',
      Icon: { Component: IconMoneySign, title: 'Ícone: Ótimo preço' },
    },
  ],
])

export const DEFAULT_GALLERY_IMAGE_HEIGHT = 768
export const DEFAULT_RECOMMENDATION_CARD_IMAGE_HEIGHT = 768
const CLOUDINARY_ID = 'v1513818385'
const DEFAULT_IMAGE_HEIGHT = 400

type TRedirectIdParams = {
  state?: string
  rest?: string
  listing: ListingQuery['listing']
  queryParams?: string
}

type TRedirectAddressParams = {
  state?: string
  city?: string
  neighborhood?: string
  street?: string
  listing: ListingQuery['listing']
}

const orientationTypeText = {
  [OrientationType.Backside]: 'Fundos',
  [OrientationType.Frontside]: 'Frente',
  [OrientationType.Inside]: 'Meio',
  [OrientationType.Lateral]: 'Lateral',
}

const SunPeriodTypeText = {
  [SunPeriodType.Evening]: 'Tarde',
  [SunPeriodType.Morning]: 'Manhã',
}

const garageTypeText = {
  [GarageType.Condominium]: 'Condomínio',
  [GarageType.Contract]: 'Escritura',
}

export const isANewListing = ({
  insertedAt,
  today = new Date(),
}: {
  insertedAt: Listing['insertedAt']
  today?: Date
}) => {
  if (!insertedAt) return false
  const diffInMilliseconds =
    new Date(today).getTime() - new Date(insertedAt).getTime()
  const dayUnitInMilliseconds = 24 * 3600 * 1000
  const diffInDays = Math.ceil(diffInMilliseconds / dayUnitInMilliseconds)
  return diffInDays < 30
}

export const buildListingsUrl = (urlPath: string) => {
  if (!urlPath || !urlPath.trim()) {
    return '/imoveis'
  }
  return `/imoveis/${urlPath}`
}

export const sortPreviousPricesByInsertedAt = (
  previousPrices: Listing['previousPrices'],
) => {
  const prices = previousPrices ? [...previousPrices] : []
  return prices.length > 0
    ? prices?.sort((a, b) =>
        a?.insertedAt && b?.insertedAt && a.insertedAt < b.insertedAt ? 1 : -1,
      )
    : []
}

export const getListingTitle = (listing: ListingQuery['listing']) => {
  if (!listing || !listing?.type || !listing?.address) return ''

  const { type, rooms, address, price } = listing
  const { neighborhood, city } = address ?? {}

  if (rooms) {
    return `${type} à venda com ${rooms} ${pluralize({
      count: rooms,
      noun: 'quarto',
    })} em ${neighborhood as string}, ${city as string} por ${toCurrency(
      price,
    )} - EmCasa`
  }

  return `${type} à venda em ${neighborhood as string}, ${
    city as string
  } por ${toCurrency(price)} - EmCasa`
}

export const getListingDescription = (listing: ListingQuery['listing']) => {
  const typePhrase = `Conheça ${listing?.type === 'Apartamento' ? 'o' : 'a'} ${
    listing?.type as string
  }`

  const addressPhrase = `à venda na ${listing?.address?.street as string}, ${
    listing?.address?.neighborhood as string
  }, ${listing?.address?.city as string}`

  const roomsPhrase = `com ${listing?.rooms as number} dormitório${
    (listing?.rooms as number) > 1 ? 's' : ''
  }`

  const areaPhrase = `${listing?.area as number}m²`

  const pricePhrase = toCurrency(listing?.price)

  const endPhrase = 'Veja fotos reais e todos os detalhes do imóvel'

  return `${typePhrase} ${addressPhrase}, ${roomsPhrase} e ${areaPhrase} por ${pricePhrase}. ${endPhrase}.`
}

export const getListingHeadingTitle = (listing: ListingQuery['listing']) => {
  if (!listing || !listing?.type) return ''

  const { type, area, rooms, garageSpots } = listing
  const startTitle = `${type} à venda com ${(area || '0') as string}m²`
  const roomsText = `${rooms || 0} ${pluralize({
    count: rooms || 0,
    noun: 'quarto',
  })}`

  const garageSpotsText = garageSpots
    ? `${garageSpots} ${pluralize({
        count: garageSpots,
        noun: 'vaga',
      })}`
    : ''

  return `${startTitle}${garageSpotsText ? ', ' : ' e '}${roomsText}${
    garageSpotsText ? ' e ' : ''
  }${garageSpotsText}`
}

type TGetListingImagesProps = {
  images: Array<Image | null> | null
  width: number
  height: number
  customProperty?: string
  maxImages?: number
  listingId: Listing['id']
  address: Listing['address']
  withWatermark?: boolean
}

export const getThumbnailUrl = ({
  filename,
  height = DEFAULT_IMAGE_HEIGHT,
  withWatermark,
  customProperty,
}: {
  filename?: string | null
  height?: number
  withWatermark?: boolean
} & Pick<TGetListingImagesProps, 'customProperty'>) => {
  if (!filename) return ''

  if (customProperty) {
    return `${
      withWatermark
        ? CLOUDINARY_IMAGE_URL_WITH_WATERMARK_OVERLAY
        : CLOUDINARY_IMAGE_URL
    }/f_auto,q_auto,c_fill/${customProperty}/${CLOUDINARY_ID}/${filename}`
  }

  return `${
    withWatermark
      ? CLOUDINARY_IMAGE_URL_WITH_WATERMARK_OVERLAY
      : CLOUDINARY_IMAGE_URL
  }/f_auto,q_auto,c_fill,h_${height}/${CLOUDINARY_ID}/${filename}`
}

export const getListingThumbnailUrl = (
  listing: ListingQuery['listing'],
  height = 400,
) => {
  const filename = listing?.images?.[0]?.filename as string

  if (!filename) return ''

  return getThumbnailUrl({ filename, height })
}

export const getListingImageAlt = (
  id: Listing['id'],
  address: Listing['address'],
) => {
  const { street, neighborhood, city, state } = address || {}
  if (id && street && neighborhood && city && state) {
    return `Imagem do imóvel ID-${id} na ${street}, ${neighborhood}, ${city} - ${state}`
  }
  return 'Imagem interna do imóvel'
}

export const getListingImages = ({
  images,
  width,
  height,
  customProperty,
  maxImages,
  listingId,
  address,
  withWatermark,
}: TGetListingImagesProps) => {
  if (!images) return null

  const listingImages = images?.filter(Boolean)
  const filteredImages = maxImages
    ? listingImages?.slice(0, Math.min(maxImages, listingImages.length))
    : listingImages

  return filteredImages?.map((image, index) => {
    return {
      id: image?.id as string,
      src: getThumbnailUrl({
        filename: image?.filename,
        height,
        customProperty: customProperty,
        withWatermark,
      }),
      alt: image?.description || getListingImageAlt(listingId, address),
      height,
      width,
      priority: index === 0,
    }
  })
}

export const getListingUrl = (listing: ListingQuery['listing']) => {
  if (!listing) return ''

  const { id, address } = listing
  const { stateSlug, citySlug, neighborhoodSlug, streetSlug } = address ?? {}

  if (!address || !stateSlug || !citySlug || !neighborhoodSlug || !streetSlug)
    return `/imoveis/${id as string}`

  return buildListingsUrl(
    `${stateSlug}/${citySlug}/${neighborhoodSlug}/${streetSlug}/id-${
      id as string
    }`,
  )
}

export const getImageDescription = (listing: ListingQuery['listing']) => {
  const { id, address } = listing ?? {}
  const { street, neighborhood, city, state } = address ?? {}

  if (!listing || !address) return ''

  return `Imagem do imóvel ID-${id as string} na ${street as string}, ${
    neighborhood as string
  }, ${city as string} - ${state as string}`
}

export const getSimilarUrl = (listing: ListingQuery['listing']) => {
  const { address, price, area } = listing ?? {}
  const { stateSlug, citySlug } = address ?? {}

  if (!area || !price || !address) return ''

  return `/imoveis/${stateSlug as string}/${
    citySlug as string
  }/preco-min-${Math.floor(price * 0.7)}/preco-max-${Math.round(
    price * 1.3,
  )}/area-min-${Math.floor(area * 0.7)}`
}

export const getTaxesValue = (listing: ListingQuery['listing']) => {
  const { taxes } = listing ?? {}
  const { itbi, propertyDeed, propertyRegistration } = taxes ?? {}

  if (!taxes) return EMPTY_PRICE_PLACEHOLDER

  const itbiValue = Number(itbi)
  const propertyDeedValue = Number(propertyDeed)
  const propertyRegistrationValue = Number(propertyRegistration)

  const sum = Math.round(
    itbiValue + propertyDeedValue + propertyRegistrationValue,
  )

  return toCurrency(sum)
}

export const getPaymentInformation = (listing: ListingQuery['listing']) => {
  const { price } = listing ?? {}

  if (!price)
    return { first: EMPTY_PRICE_PLACEHOLDER, last: EMPTY_PRICE_PLACEHOLDER }

  const downPaymentValue = (MINIMUM_ENTRY_PERCENTAGE * price) / 100

  const balance = getBalance(price, downPaymentValue)
  const amortization = getAmortization(balance, DEFAULT_TERM)
  const firstPayment = getFirstPayment(balance, amortization)
  const lastPayment = getLastPayment(amortization)

  return { first: toCurrency(firstPayment), last: toCurrency(lastPayment) }
}

// Infos

export type TListingInfo = {
  title?: string
  text?: string
  value?: string | number
  Icon?: {
    Component: FC<TIconProps>
    title: string
    color?: string
  }
}

export const getFloorInfo = (floor: string | undefined) => {
  const info: TListingInfo = {
    title: 'Andar do imóvel',
    Icon: {
      Component: IconBuilding,
      title: 'Ícone: documento',
    },
  }

  let textInfo: TListingInfo = {
    ...info,
    value: floor,
  }

  const floorInfoMap = new Map([
    ['subsolo', { text: 'Abaixo do térreo', value: 'Subsolo' }],
    ['terreo', { value: 'Térreo' }],
    ['baixo', { text: 'Abaixo do 4º andar', value: 'Andar baixo' }],
    [
      'intermediario',
      { text: 'Abaixo do 8º andar', value: 'Andar intermediário' },
    ],
    ['alto', { text: 'Acima do 7º andar', value: 'Andar alto' }],
  ])

  const isNumber = !Number.isNaN(Number(floor))

  if (isNumber) {
    const floorNumber = Number(floor)
    let infoMapKey = 'terreo'

    if (floorNumber < 0) {
      infoMapKey = 'subsolo'
    }
    if (floorNumber > 0 && floorNumber < 4) {
      infoMapKey = 'baixo'
    }
    if (floorNumber > 3 && floorNumber < 8) {
      infoMapKey = 'intermediario'
    }
    if (floorNumber > 7) {
      infoMapKey = 'alto'
    }

    textInfo = { ...info, ...floorInfoMap?.get(infoMapKey) } || textInfo
  }

  return textInfo
}

export const getSubwayDistanceInfo = (
  subwayDistance: number | null,
  subwayStation?: string | string[] | null,
) => {
  if (!subwayDistance) return null

  const normalizeStationName = (name: string) => {
    return name.includes('Estação') ? name : `Estação ${name}`
  }

  const getSubwayNames = (name: string | string[]) => {
    if (typeof name === 'object') {
      return name?.map((station) => normalizeStationName(station)).join(', ')
    }
    return normalizeStationName(name)
  }

  return {
    title: 'Próximo ao metrô',
    text:
      subwayStation && subwayDistance <= SUBWAY_DISTANCE
        ? `${getSubwayNames(subwayStation)}`
        : `Até ${SUBWAY_DISTANCE} metros de distância`,
    value: subwayDistance <= SUBWAY_DISTANCE ? 'Sim' : 'Não',
    Icon: {
      Component: IconSubway,
      title: 'Ícone: metrô',
    },
  }
}

export const getElevatorsInfo = (elevators: number | null | undefined) => {
  return {
    title: 'Elevador',
    value: elevators || '--',
    Icon: {
      Component: IconElevator,
      title: 'Ícone: elevador',
    },
  }
}

export const getListingInfos = (listing: ListingQuery['listing']) => {
  const {
    area,
    bathrooms,
    elevators,
    floor,
    garageSpots,
    garageType,
    maintenanceFee,
    propertyTax,
    rooms,
    subwayDistance,
    subwayStation,
    suites,
    type,
  } = listing ?? {}

  if (!listing) return []

  const floorInfo =
    type === 'Apartamento' && floor ? [getFloorInfo(floor ?? '--')] : []

  const subwayInfo = subwayDistance
    ? [getSubwayDistanceInfo(subwayDistance, subwayStation)]
    : []

  const elevatorsInfo = type !== 'Casa' ? [getElevatorsInfo(elevators)] : []

  return [
    {
      title: 'Metragem',
      text: 'Área total',
      value: area ? `${area}m²` : '--',
      Icon: {
        Component: IconArea,
        title: 'Ícone: área',
      },
    },
    {
      title: 'Total de quartos',
      text: 'Inclui suíte',
      value: minTwoDigits(rooms || 0),
      Icon: {
        Component: IconBed,
        title: 'Ícone: cama',
      },
    },
    {
      title: 'Suítes',
      text: 'Quarto com banheiro',
      value: minTwoDigits(suites || 0),
      Icon: {
        Component: IconBed,
        title: 'Ícone: cama',
      },
    },
    {
      title: 'Banheiros sociais',
      text: 'Exclui lavabo e suíte',
      value: minTwoDigits(bathrooms || 0),
      Icon: {
        Component: IconShower,
        title: 'Ícone: chuveiro',
      },
    },
    ...floorInfo,
    ...elevatorsInfo,
    {
      title: 'IPTU anual',
      text: 'IPTU do último ano',
      value: toCurrency(propertyTax, { placeholder: '--' }),
      Icon: {
        Component: IconBuildingCircleMoneySign,
        title: 'Ícone: documento',
      },
    },
    {
      title: 'Valor do condomínio',
      text: 'Valor mensal',
      value: toCurrency(maintenanceFee, { placeholder: '--' }),
      Icon: {
        Component: IconHouseCircleMoneySign,
        title: 'Ícone: casa',
      },
    },
    {
      title: 'Vagas de garagem',
      text: garageTypeText[garageType as GarageType],
      value: minTwoDigits(garageSpots || 0),
      Icon: {
        Component: IconCar,
        title: 'Ícone: carro',
      },
    },
    ...subwayInfo,
  ] as Array<TListingInfo>
}

export const getListingSecondaryInfos = (listing: ListingQuery['listing']) => {
  const { sunPeriod, orientation } = listing ?? {}

  if (!listing || (!sunPeriod && !orientation)) return []

  return [
    {
      title: 'Iluminação solar',
      value: SunPeriodTypeText[sunPeriod as SunPeriodType] || '--',
      Icon: {
        Component: IconSun,
        title: 'Ícone: documento',
      },
    },
    {
      title: 'Orientação do imóvel',
      value: orientationTypeText[orientation as OrientationType] || '--',
      Icon: {
        Component: IconImage,
        title: 'Ícone: documento',
      },
    },
  ] as Array<TListingInfo>
}

export const getListingBuildingInfos = (listing: ListingQuery['listing']) => {
  const { floorCount, unitPerFloor, tags } = listing ?? {}

  if (!listing || (!floorCount && !unitPerFloor && !tags)) return []
  const concierge = tags?.find((item) => item?.category === 'concierge')

  return [
    {
      title: 'Total de andares',
      value: floorCount || '--',
      Icon: {
        Component: IconBuildingHeight,
        title: 'Ícone: documento',
      },
    },
    {
      title: 'Aptos por andar',
      value: unitPerFloor || '--',
      Icon: {
        Component: IconCondominium,
        title: 'Ícone: documento',
      },
    },
    {
      title: 'Portaria',
      value: concierge?.name || '--',
      Icon: {
        Component: IconConcierge,
        title: 'Ícone: documento',
      },
    },
  ] as Array<TListingInfo>
}

// Listing tags

type TTag = Pick<Tag, 'category' | 'name' | 'nameSlug'>

export const sortTagsByNameSlug = (a: TTag, b: TTag) => {
  if ((a.nameSlug && b.nameSlug && a.nameSlug > b.nameSlug) || 0) return 1
  if ((a.nameSlug && b.nameSlug && a.nameSlug < b.nameSlug) || 0) return -1
  return 0
}

export const reduceListingTags = (arr: Array<TTag>, tag: TTag | null) => {
  if (tag?.category === 'realty' || tag?.category === 'infrastructure') {
    return [
      ...arr,
      {
        category: tag?.category || null,
        name: tag?.name || null,
        nameSlug: tag?.nameSlug || null,
      },
    ]
  }
  return arr
}

export const getTagsFromCategory = (category: string, tags: Array<TTag> = []) =>
  tags?.filter((tag) => tag?.category === category).sort(sortTagsByNameSlug) ||
  []

export const getListingUnavailableTags = (
  tags: Array<TTag> = [],
  listingTags: Array<TTag> = [],
) =>
  tags
    ?.reduce(reduceListingTags, [] as Array<TTag>)
    .sort(sortTagsByNameSlug)
    .filter(
      (tag) => !listingTags?.find((item) => item.nameSlug === tag.nameSlug),
    ) || []

// Address

export const getFullAddress = (listing: ListingQuery['listing']) => {
  const { address } = listing ?? {}

  if (!address) return ''

  return `${address.street as string}, ${address.neighborhood as string}, ${
    address.city as string
  }`
}

export const getListingAddressMapsUrl = (listing: ListingQuery['listing']) => {
  const { address } = listing ?? {}

  if (!address) return ''

  return `https://www.google.com/maps/search/?api=1&query=${String(
    address.lat,
  )},${String(address.lng)}`
}

/**
 * It generates a url with location and features nearby according to Google Maps Search API
 * >> Might break one day
 * URL Example: https://www.google.com/maps/search/Esta%C3%A7%C3%B5es-de-transporte-p%C3%BAblico/@-23.0042362,-43.36834289999999,17z/data=!3m1!4b1
 * Explanation: /search/<encoded-feature-keywords>/@lat,lng,zoom/data=<protobuf-data>
 * The 'data=!3m1!4b1' part is a protobuf data, used to store information and cannot be parsed or read, but it shows this pattern for every result.
 * Without it, the maps is rendered with features listed but with no markers on map
 * */
export const getListingFeatureMapsUrl = (
  listing: ListingQuery['listing'],
  featureSearch: string,
) => {
  const { address } = listing ?? {}

  if (!address) return ''

  const encodedFeatureString = encodeURI(featureSearch)
  const url = `https://www.google.com/maps/search/${encodedFeatureString}/@${String(
    address.lat,
  )},${String(address.lng)},17z/data=!3m1!4b1`

  return url
}

export const getListingLocationFeatures = (
  listing: ListingQuery['listing'],
) => [
  {
    key: 'transport',
    text: 'Ver transporte público próximo',
    anchorProps: {
      href: getListingFeatureMapsUrl(listing, 'Estações-de-transporte-público'),
      target: '_blank',
      Icon: {
        Component: IconSubway,
        title: 'Transportes próximos ao endereço',
      },
    },
  },
  {
    key: 'market',
    text: 'Ver mercados próximos',
    anchorProps: {
      href: getListingFeatureMapsUrl(listing, 'Mercados'),
      target: '_blank',
      Icon: {
        Component: IconMoneySign,
        title: 'Mercados próximos',
      },
    },
  },
]

/*
  https://emcasa.com/imoveis/124 => https://emcasa.com/imoveis/rj/rio-de-janeiro/gavea/rua-major-rubens-vaz/id-124
*/
export const redirectId = ({
  state,
  rest,
  listing,
  queryParams,
}: TRedirectIdParams) => {
  if (state) return false

  const pathname = `${getListingUrl(listing)}${rest ?? ''}`
  const redirect = `${pathname}${queryParams ? `?${queryParams}` : ''}`

  return redirect
}

/*
  Redirect id to correct address
     https://emcasa.com/imoveis/rj/rio-de-janeiro/gavea/rua-major-rubens-vaz/id-125 (address from id 124)
  => https://emcasa.com/imoveis/rj/rio-de-janeiro/botafogo/rua-clarice-indio-do-brasil/id-125
*/
export const redirectAddress = ({
  state,
  city,
  neighborhood,
  street,
  listing,
}: TRedirectAddressParams) => {
  if (!listing || !listing.address) return false

  const { stateSlug, citySlug, neighborhoodSlug, streetSlug } = listing.address

  if (
    state !== stateSlug ||
    city !== citySlug ||
    neighborhood !== neighborhoodSlug ||
    street !== streetSlug
  ) {
    return getListingUrl(listing)
  }

  return false
}

export const getListingPreviousPrice = (listing: Listing) => {
  const { previousPrices } = listing

  const prices = sortPreviousPricesByInsertedAt(previousPrices)
  return prices[0]?.price
}

export const getListingSocialProof = (listing: ListingQuery['listing']) => {
  const { tags, insertedAt, priceRecentlyReduced, normalizedLiquidityRatio } =
    listing ?? {}

  const isBlackNovember = tags?.find(
    (item) => item?.nameSlug === 'blacknovember',
  )
  const isDevelopment = tags?.find((item) => item?.nameSlug === 'lancamento')

  if (isBlackNovember?.nameSlug === 'blacknovember') {
    return listingSocialProof.get('blacknovember')
  }
  if (isDevelopment) {
    return listingSocialProof.get('development')
  }
  if (isANewListing({ insertedAt })) {
    return listingSocialProof.get('newListing')
  }
  if (priceRecentlyReduced) {
    return listingSocialProof.get('priceRecentlyReduced')
  }
  if (normalizedLiquidityRatio && normalizedLiquidityRatio >= 6) {
    return listingSocialProof.get('normalizedLiquidityRatio')
  }
  return null
}

export const getListingLabel = (listing: ListingQuery['listing']) => {
  const socialProofProps = getListingSocialProof(listing)

  if (!socialProofProps) return null

  const labelProps = {
    ...socialProofProps,
    text: socialProofProps?.title,
    variant: socialProofProps?.labelVariant,
  }

  return omit(labelProps, ['title', 'labelVariant']) as TLabelProps
}

export const getListingLabelActive = (listing: ListingQuery['listing']) => {
  const { isActive } = listing ?? {}
  const labelProps = {
    text: isActive ? 'Disponível' : 'Imóvel indisponível',
    variant: isActive ? 'green' : 'pink',
  }

  return labelProps as TLabelProps
}

export const getListingLoggingInfo = (listing: Listing) => {
  const { id, address, images, related } = listing
  return {
    ...listing,
    images: images?.length,
    listingId: id,
    address,
    neighborhood: address?.neighborhoodSlug,
    related: related?.listings?.length,
    city: address?.citySlug,
  }
}

export const isListingIndexable = (listing: Listing): boolean => {
  return Boolean(listing.area)
}

// It's used to set ListingCard visualized prop if it's on localStorage

export const LOCALSTORAGE_LISTING_VISUALIZED_KEY = 'EmCasaSessionListings'

export const setEmCasaSessionListings = (id: string) => {
  const itemStorage = localStorage?.getItem(LOCALSTORAGE_LISTING_VISUALIZED_KEY)

  const localStorageItems: Array<string> = itemStorage
    ? (JSON.parse(itemStorage) as Array<string>)
    : []

  if (!localStorageItems?.includes(id)) {
    localStorage.setItem(
      LOCALSTORAGE_LISTING_VISUALIZED_KEY,
      JSON.stringify([...localStorageItems, id]),
    )
  }
}

export const getEmCasaSessionListings = () => {
  const itemStorage = localStorage?.getItem(LOCALSTORAGE_LISTING_VISUALIZED_KEY)

  return itemStorage ? (JSON.parse(itemStorage) as Array<string>) : []
}
