import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconCircleMoneySign: FC<TIconProps> = ({
  color,
  width,
  height,
  title,
}) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 20c4.4183 0 8-3.5817 8-8 0-4.41828-3.5817-8-8-8-4.41828 0-8 3.58172-8 8 0 4.4183 3.58172 8 8 8Zm-.6554-2.8856c.056.056.128.084.216.084h.852c.08 0 .148-.028.204-.084.056-.056.084-.128.084-.216v-.828c.568-.072 1.06-.216 1.476-.432.424-.216.752-.5.984-.852.232-.36.348-.772.348-1.236 0-.48-.104-.876-.312-1.188-.2-.32-.52-.58-.96-.78-.44-.2-1.012-.372-1.716-.516-.464-.12-.832-.228-1.104-.324-.272-.104-.468-.22-.588-.348-.12-.136-.18-.3-.18-.49196 0-.296.116-.516.348-.66.232-.144.544-.216.936-.216.368 0 .672.08.912.24.24.152.38.332.42.54.048.08.104.136.168.168.064.03196.132.04796.204.04796h1.356c.072 0 .132-.02396.18-.07196.048-.056.072-.116.072-.18-.008-.288-.112-.584-.312-.888-.192-.312-.476-.588-.852-.828-.368-.248-.828-.416-1.38-.504v-.852c0-.088-.028-.16-.084-.216-.056-.056-.124-.084-.204-.084h-.852c-.088 0-.16.028-.216.084-.056.056-.084.128-.084.216v.828c-.8.104-1.42804.376-1.88404.816-.448.44-.672.976-.672 1.608 0 .47996.104.88396.312 1.21196.216.32.532.584.948.792.41604.2.92404.364 1.52404.492.504.12.904.232 1.2.336.304.104.524.22.66.348.144.128.216.296.216.504 0 .288-.132.516-.396.684-.256.168-.644.252-1.164.252-.32 0-.588-.04-.804-.12-.216-.088-.388-.196-.516-.324-.12-.128-.208-.26-.264-.396-.048-.064-.1-.116-.156-.156-.056-.048-.136-.072-.24-.072H8.74056c-.072 0-.136.028-.192.084-.048.048-.072.104-.072.168.016.36.128.704.336 1.032.208.328.516.608.924.84.40804.224.91604.372 1.52404.444v.828c0 .088.028.16.084.216Z"
        fill={variableColor}
      />
    </BaseIcon>
  )
}

export default IconCircleMoneySign
