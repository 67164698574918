import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconShare: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        d="M18 8C19.6569 8 21 6.65685 21 5C21 3.34315 19.6569 2 18 2C16.3431 2 15 3.34315 15 5C15 5.19197 15.018 5.37973 15.0525 5.56168L7.93932 9.71103C7.41636 9.26751 6.73942 9 6 9C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15C6.73942 15 7.41636 14.7325 7.93932 14.289L15.0525 18.4383C15.018 18.6203 15 18.808 15 19C15 20.6569 16.3431 22 18 22C19.6569 22 21 20.6569 21 19C21 17.3431 19.6569 16 18 16C17.2606 16 16.5836 16.2675 16.0607 16.711L8.94751 12.5617C8.98197 12.3797 9 12.192 9 12C9 11.808 8.98197 11.6203 8.94751 11.4383L16.0607 7.28897C16.5836 7.73249 17.2606 8 18 8Z"
        fill={variableColor}
      />
    </BaseIcon>
  )
}

export default IconShare
