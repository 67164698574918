import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconCondominium: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 32 32" title={title}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.6667 24.0262H26.002L26.002 13.9308L18.6687 8.50391L17.3581 9.4738L21.9663 13.4134C22.4108 13.7934 22.6667 14.3489 22.6667 14.9336V24.0262ZM17.479 6.89625L15.7981 8.14015L14.633 7.14407C13.8846 6.50432 12.782 6.50432 12.0337 7.14407L4.70037 13.4134C4.25591 13.7934 4 14.3489 4 14.9336V24.033C4 25.1376 4.89543 26.033 6 26.033H20.6667C20.7225 26.033 20.7778 26.0307 20.8325 26.0262H26.002C27.1066 26.0262 28.002 25.1308 28.002 24.0262V13.9308C28.002 13.2968 27.7014 12.7003 27.1917 12.3232L19.8584 6.89625C19.1515 6.37313 18.1859 6.37313 17.479 6.89625ZM15.3333 24.033H20.6667L20.6667 14.9336L13.3333 8.66426L6 14.9336V24.033H11.3333V19.3437H15.3333V24.033Z"
        fill={variableColor}
      />
    </BaseIcon>
  )
}

export default IconCondominium
