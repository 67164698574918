import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconPerson: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0029 8C16.0029 10.2091 14.212 12 12.0029 12C9.79376 12 8.0029 10.2091 8.0029 8C8.0029 5.79086 9.79376 4 12.0029 4C14.212 4 16.0029 5.79086 16.0029 8ZM14.0029 8C14.0029 9.10457 13.1075 10 12.0029 10C10.8983 10 10.0029 9.10457 10.0029 8C10.0029 6.89543 10.8983 6 12.0029 6C13.1075 6 14.0029 6.89543 14.0029 8Z"
        fill={variableColor}
      />
      <path
        d="M12.0029 13C7.9685 13 5.66524 14.6537 4.40562 16.4192C3.79224 17.2789 3.44728 18.1346 3.25483 18.7745C3.15824 19.0957 3.09883 19.3666 3.06304 19.5623C3.04511 19.6604 3.03303 19.74 3.02513 19.7982C3.02119 19.8273 3.01828 19.8511 3.01621 19.8692L3.01371 19.892L3.01288 19.9001L3.01256 19.9033L3.01243 19.9047C3.01243 19.9047 3.01231 19.906 4.00788 20L3.01231 19.906C2.9604 20.4559 3.36405 20.9437 3.91389 20.9956C4.46214 21.0473 4.94871 20.6462 5.00299 20.0988L4.40562 16.4192L5.00325 20.0964C5.00325 20.0964 5.00496 20.0819 5.00698 20.0671C5.01101 20.0374 5.01835 19.9881 5.03042 19.922C5.05462 19.7897 5.09751 19.5918 5.17009 19.3505C5.31599 18.8654 5.57692 18.2211 6.03373 17.5808C6.91447 16.3463 8.60872 15 12.0029 15C15.3971 15 17.0928 16.3464 17.9749 17.5812C18.4323 18.2216 18.6938 18.8661 18.8401 19.3512C18.9129 19.5926 18.956 19.7905 18.9803 19.9228C18.9924 19.9889 18.9998 20.0382 19.0039 20.0679C19.0059 20.0827 19.0071 20.0926 19.0076 20.0972L19.0079 20.0995C19.0626 20.6469 19.5495 21.0477 20.0977 20.9955C20.6475 20.9431 21.0508 20.455 20.9984 19.9052L20.0174 19.9986C20.9984 19.9052 20.9983 19.9039 20.9983 19.9039L20.9978 19.8993L20.997 19.8912L20.9945 19.8683C20.9932 19.8578 20.9917 19.8452 20.9899 19.8308C20.9886 19.8206 20.9871 19.8094 20.9855 19.7974C20.9775 19.7392 20.9654 19.6596 20.9474 19.5615C20.9114 19.3658 20.8518 19.0949 20.7549 18.7738C20.562 18.1339 20.2163 17.2784 19.6023 16.4188C18.3415 14.6536 16.0373 13 12.0029 13Z"
        fill={variableColor}
      />
    </BaseIcon>
  )
}

export default IconPerson
