export const slugify = (...args: (string | number)[]): string => {
  const value = args.join(' ')

  return value
    .normalize('NFD') // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
    .replace(/\s+/g, '-') // separator
}

export const nameFromSlug = (slug: string) => {
  if (!slug) return ''

  return slug
    .replace(/-/g, ' ')
    .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()))
}

export const pluralize = ({
  count,
  noun,
  pluralNoun,
}: {
  count: number
  noun: string
  pluralNoun?: string
}) => {
  if (pluralNoun) {
    return `${count !== 1 ? pluralNoun : noun}`
  }
  return `${noun}${count !== 1 ? 's' : ''}`
}

export const replaceHTMLEntitiesAndTags = (text: string) => {
  const HTML_ENTITIES_REPLACEMENT = [['&#8211;', '–']]

  let result = text

  for (const [entity, replacement] of HTML_ENTITIES_REPLACEMENT) {
    const entityRegex = new RegExp(entity, 'gi')
    result = result.replace(entityRegex, replacement)
  }

  const entityRegex = /&(?:[a-z]+|#\d+);/gi
  result = result.replace(entityRegex, '')

  const tagRegex = /<\/?[^>]+(>|$)/g
  result = result.replace(tagRegex, '')

  return result
}
