import { FC } from 'react'
import styles from './Loader.module.scss'

type TProps = {
  size?: number
}

const Loader: FC<TProps> = ({ size = 50 }) => (
  <span className={styles.ecLoader} data-testid="ecLoader">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      width={size}
      height={size}
      className={styles.ecLoaderIcon}
    >
      <title>Ícone de carregamento</title>
      <path
        fill="#ff2b7f"
        d="M10 50a40 40 0 0080 0 40 42 0 01-80 0"
        style={{ animationPlayState: 'paused' }}
      />
    </svg>
  </span>
)

export default Loader
