import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconCheck: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        d="M20.6705 6.9205C21.1098 6.48116 21.1098 5.76884 20.6705 5.3295C20.2312 4.89017 19.5188 4.89017 19.0795 5.3295L8.625 15.784L4.9205 12.0795C4.48116 11.6402 3.76884 11.6402 3.3295 12.0795C2.89017 12.5188 2.89017 13.2312 3.3295 13.6705L7.8295 18.1705C8.26884 18.6098 8.98116 18.6098 9.4205 18.1705L20.6705 6.9205Z"
        fill={variableColor}
      />
    </BaseIcon>
  )
}

export default IconCheck
