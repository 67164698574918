import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconCogwheel: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fill={variableColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 2a1 1 0 0 1 1 1v.54c0 .479.341.884.798 1.025.539.167 1.053.39 1.534.664.427.242.972.205 1.32-.142l.437-.438a1 1 0 0 1 1.415 0l.707.707a1 1 0 0 1 0 1.414l-.55.55c-.33.33-.382.84-.177 1.257.177.36.326.736.446 1.124.141.457.546.799 1.024.799H21a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-.548c-.478 0-.883.341-1.024.798a7.727 7.727 0 0 1-.551 1.329c-.224.423-.179.95.16 1.288l.791.792a1 1 0 0 1 0 1.414l-.707.707a1 1 0 0 1-1.414 0l-.792-.792c-.338-.338-.865-.383-1.288-.16a7.727 7.727 0 0 1-1.33.552c-.456.142-.797.546-.797 1.024V21a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1v-.546c0-.478-.341-.883-.799-1.024a7.728 7.728 0 0 1-1.124-.446c-.418-.205-.928-.153-1.257.177l-.552.552a1 1 0 0 1-1.414 0l-.708-.707a1 1 0 0 1 0-1.414l.44-.44c.348-.348.385-.893.143-1.32a7.734 7.734 0 0 1-.664-1.534c-.141-.457-.546-.798-1.024-.798H3a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1h.539c.478 0 .883-.341 1.024-.799.143-.465.33-.911.553-1.335.224-.423.179-.95-.16-1.288l-.31-.31a1 1 0 0 1 0-1.414l.708-.708a1 1 0 0 1 1.414 0l.31.31c.338.339.865.384 1.288.16.424-.224.87-.41 1.335-.553.457-.141.799-.546.799-1.024V3a1 1 0 0 1 1-1h1Zm-.504 14.774a4.778 4.778 0 1 0 0-9.555 4.778 4.778 0 0 0 0 9.555Z"
      />
    </BaseIcon>
  )
}

export default IconCogwheel
