import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconSearch: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9654 16.2729C13.6012 17.3543 11.876 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 11.819 17.3929 13.4963 16.3702 14.8402L21.6402 19.2318C22.0645 19.5853 22.1218 20.2159 21.7682 20.6402C21.4147 21.0645 20.7841 21.1218 20.3598 20.7682L14.9654 16.2729ZM16 10C16 13.3137 13.3137 16 10 16C6.68629 16 4 13.3137 4 10C4 6.68629 6.68629 4 10 4C13.3137 4 16 6.68629 16 10Z"
        fill={variableColor}
      />
    </BaseIcon>
  )
}

export default IconSearch
