import { AnchorHTMLAttributes, ButtonHTMLAttributes, FC } from 'react'
import Link, { LinkProps } from 'next/link'
import { TIconComponent } from 'icons/types'
import { splitAnchorLinkProps, TAnchorElementProps } from 'components/Anchor'
import classNames from 'classnames'
import styles from './Button.module.scss'

type TButtonAttributes = ButtonHTMLAttributes<HTMLButtonElement>
type TAnchorAttributes = Pick<LinkProps, 'prefetch'> &
  AnchorHTMLAttributes<HTMLAnchorElement>

type TConditionalProps =
  | ({ element?: 'a' } & TAnchorAttributes)
  | ({ element?: 'button' } & TButtonAttributes)

export type TProps = {
  size?: 'small' | 'medium' | 'big'
  theme?:
    | 'outline'
    | 'white'
    | 'pink'
    | 'purple'
    | 'green'
    | 'grey'
    | 'ghost'
    | 'opacity'
  shape?: 'rounded' | 'circle'
  hasNotification?: boolean
  Icon?: Omit<TIconComponent, 'width' | 'height'>
} & TConditionalProps

const Button: FC<TProps> = ({
  element = 'button',
  size = 'medium',
  theme = 'outline',
  shape = 'rounded',
  className,
  hasNotification = false,
  Icon,
  children,
  ...rest
}) => {
  const themeMap = {
    outline: {
      className: styles.ecButtonThemeOutline,
    },
    ghost: {
      className: styles.ecButtonThemeGhost,
    },
    white: {
      className: styles.ecButtonThemeWhite,
    },
    pink: {
      className: styles.ecButtonThemePink,
    },
    purple: {
      className: styles.ecButtonThemePurple,
    },
    green: {
      className: styles.ecButtonThemeGreen,
    },
    grey: {
      className: styles.ecButtonThemeGrey,
    },
    opacity: {
      className: styles.ecButtonThemeOpacity,
    },
  }

  const sizeMap = {
    small: {
      iconSize: 20,
      className: styles.ecButtonSizeSmall,
    },
    medium: {
      iconSize: 24,
      className: null,
    },
    big: {
      iconSize: 28,
      className: styles.ecButtonSizeBig,
    },
  }

  const shapeMap = {
    rounded: {
      className: null,
    },
    circle: {
      className: styles.ecButtonShapeCircle,
    },
  }

  const buttonClasses = classNames(
    className,
    styles.ecButton,
    sizeMap[size].className,
    shapeMap[shape].className,
    themeMap[theme].className,
    {
      [styles.ecButtonHasNotification]: hasNotification,
    },
  )

  const getContent = () => (
    <span className={styles.ecButtonContent}>
      {Icon && (
        <Icon.Component
          color={Icon?.color}
          title={Icon?.title}
          width={sizeMap[size].iconSize}
          height={sizeMap[size].iconSize}
        />
      )}
      {children}
    </span>
  )

  if (element === 'a') {
    const { linkProps, anchorProps } = splitAnchorLinkProps({
      ...(rest as TAnchorElementProps),
      className: buttonClasses,
    })

    return (
      <Link {...linkProps} {...anchorProps}>
        {getContent()}
      </Link>
    )
  }

  const buttonProps = {
    ...(rest as TButtonAttributes),
    className: buttonClasses,
  }

  return (
    <button type="button" {...buttonProps}>
      {getContent()}
    </button>
  )
}

export default Button
