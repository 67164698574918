import * as Sentry from '@sentry/nextjs'
import { BrowserTracing } from '@sentry/tracing'
import { SENTRY_DEBUG, SENTRY_DSN, SENTRY_ENVIRONMENT } from 'config'
import { User } from 'graphql/types'

export type TSentryLog = {
  error: unknown
  errorInfo?: Record<string, unknown>
  callback?: (scope: Sentry.Scope) => void
}

type TUserProps = User & {
  uiVersion: string
  fcmToken: string | null
  experiments: Array<string>
}

export const sentryCommonConfig = {
  enabled: Boolean(SENTRY_DSN),
  environment: SENTRY_ENVIRONMENT,
  debug: SENTRY_ENVIRONMENT === 'development' && SENTRY_DEBUG === 'true',
  dsn: SENTRY_DSN,
  whitelistUrls: [/https?:\/\/.*?\.?emcasa\.com/], // valid for any url that contains emcasa.com
}

export const clientConfig = {
  integrations: [new BrowserTracing()],
}

export function identify(user: TUserProps) {
  if (user) {
    Sentry.configureScope((scope) => {
      scope.setUser(user as Sentry.User)
    })
  }
}

export function log(
  message: Sentry.Breadcrumb['message'],
  {
    category,
    level = 'info',
    ...data
  }: Omit<Sentry.Breadcrumb, 'message'> = {},
) {
  Sentry.addBreadcrumb({
    message,
    category,
    level,
    data,
  })
}

export function capture({ error, errorInfo = {}, callback }: TSentryLog) {
  Sentry.withScope((scope) => {
    Object.keys(errorInfo).forEach((key) => {
      scope.setExtra(key, errorInfo[key])
    })

    if (callback) callback(scope)

    Sentry.captureException(error)
  })
}
