type TOptions = {
  maximumFractionDigits?: number
  minimumFractionDigits?: number
  placeholder?: string
}

type TToShortNameOptions = {
  completeSuffix?: boolean
}

export const EMPTY_PRICE_PLACEHOLDER = 'R$ --'

export const toCurrency = (
  num?: number | null,
  {
    maximumFractionDigits = 2,
    minimumFractionDigits,
    placeholder = EMPTY_PRICE_PLACEHOLDER,
  }: TOptions = {},
) => {
  if (!num) return placeholder

  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    maximumFractionDigits,
    minimumFractionDigits: minimumFractionDigits ?? maximumFractionDigits,
  }).format(num)
}

export const minTwoDigits = (num: number) => (num < 10 ? '0' : '') + String(num)

export const toCurrencyNoStyle = (num: number) =>
  new Intl.NumberFormat('pt-BR').format(num)

export const toValidNumber = (num: string | number) => {
  if (!num) return null
  if (typeof num === 'number') return num
  return parseInt(num.replace(/\D/g, ''), 10)
}

export const toShortPrice = (
  price: number,
  { completeSuffix }: TToShortNameOptions = {},
) => {
  const format = (number: number) => {
    if (number >= 1000000) {
      // eslint-disable-next-line no-nested-ternary
      const suffix = completeSuffix
        ? number < 2000000
          ? ' milhão'
          : ' milhões'
        : 'M'

      return `${(number / 1000000).toFixed(1).replace(/\.0$/, '')}${suffix}`
    }
    if (number >= 1000) {
      return `${(number / 1000).toFixed(0).replace(/\.0$/, '')} mil`
    }
    return number
  }
  return format(price)
}
