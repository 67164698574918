import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconBuilding: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fill={variableColor}
        fillRule="evenodd"
        d="M8.91 3c-.905 0-1.637.733-1.637 1.636v6.825A4.911 4.911 0 0 0 8.909 21c1.454 0 2.76-.632 3.66-1.636h7.795V4.636c0-.903-.733-1.636-1.637-1.636H8.91Zm0 8.182a4.91 4.91 0 0 1 4.84 4.09h1.705v2.455h3.272V4.637H8.91v6.545Zm1.636-4.91h1.636V7.91h-1.636V6.273Zm4.909 0h1.636V7.91h-1.637V6.273Zm0 3.273h1.636v1.637h-1.637V9.545Zm-4.91 0h1.637v1.637h-1.636V9.545Zm4.91 3.273h1.636v1.637h-1.637v-1.637Zm-8.472 1.926a.409.409 0 1 1 .579-.579l1.347 1.347 1.347-1.347a.409.409 0 1 1 .579.579L9.488 16.09l1.347 1.347a.409.409 0 1 1-.579.579L8.91 16.67l-1.347 1.346a.409.409 0 1 1-.579-.578l1.348-1.347-1.348-1.347Z"
        clipRule="evenodd"
      />
    </BaseIcon>
  )
}

export default IconBuilding
