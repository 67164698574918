import { User } from 'graphql/types'

declare global {
  interface Window {
    dataLayer?: object[]
  }
}

export type TGTMEvent = {
  event: string
  action: string
  props?: Record<string, unknown>
}

type TGtmEventsObject = Record<string, TGTMEvent>

type TLogEventParams = {
  event: TGTMEvent['event']
  props: TGTMEvent['props']
  gtmEventsObject?: TGtmEventsObject
}

const GTM_EVENTS: TGtmEventsObject = {
  'page-open': {
    action: 'Page Open',
    event: 'page_open',
    props: {},
  },
  'listing-detail-open': {
    action: 'Open Listing',
    event: 'listing_detail_open',
    props: {
      listing_pagetype: 'offerdetail',
    },
  },
  'listing-detail-open-visit-form': {
    action: 'Open Visit Form',
    event: 'listing_detail_open_visit_form',
    props: {
      listing_pagetype: 'conversionintent',
    },
  },
  'listing-detail-schedule-visit': {
    action: 'Schedule Visit',
    event: 'listing_detail_schedule_visit',
    props: {
      listing_pagetype: 'conversion',
    },
  },
  'listing-detail-contact-button': {
    action: 'Listing Detail Contact Button',
    event: 'listing_detail_contact_button',
    props: {},
  },
  'listing-detail-favorite-listing': {
    action: 'Listing Detail Favorite Listing',
    event: 'listing_detail_favorite_listing',
    props: {},
  },
  'listing-detail-open-interest': {
    action: 'Listing Detail Open Interest',
    event: 'listing_detail_open_interest',
    props: {},
  },
  'buyer-landing-page': {
    action: 'Buyer Landing Page',
    event: 'buyer_landing_page',
    props: {
      listing_pagetype: 'home',
    },
  },
  'buyer-landing-search-cta': {
    action: 'Buyer Landing Search CTA',
    event: 'buyer_landing_search_cta',
  },
  'listing-search-open': {
    action: 'Listing Search Open',
    event: 'listing_search_open',
    props: {},
  },
  'listing-search-results': {
    action: 'Listing Search Results',
    event: 'listing_search_results',
    props: {},
  },
  'listing-search-favorite-listing': {
    action: 'Listing Search Favorite Listing',
    event: 'listing_search_favorite_listing',
    props: {},
  },
  'you-setup-send': {
    action: 'You Setup Send',
    event: 'you_setup_send',
    props: {},
  },
  'you-setup-send-success': {
    action: 'You Setup Send Success',
    event: 'you_setup_send_success',
    props: {},
  },
  'you-setup-send-error': {
    action: 'You Setup Send Error',
    event: 'you_setup_send_error',
    props: {},
  },
  'you-setup-success': {
    action: 'You Setup Success',
    event: 'you_setup_success',
    props: {},
  },
}

const getDataLayer = () => {
  window.dataLayer = window.dataLayer || []
  return window.dataLayer
}

export function identify(user: User) {
  getDataLayer().push({
    event: 'identify',
    ...user,
  })
}

export const logEvent = ({
  event,
  props,
  gtmEventsObject = GTM_EVENTS,
}: TLogEventParams) => {
  const gtmEvent = gtmEventsObject[event]

  if (gtmEvent) {
    const { props: gtmObjectEventProps, ...restGtmEvent } = gtmEvent

    getDataLayer().push({
      ...restGtmEvent,
      ...gtmObjectEventProps,
      ...props,
    })
  }
}
