import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconBallonDot: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <path
          fill={variableColor}
          d="M8 13c.55228 0 1-.4477 1-1s-.44772-1-1-1-1 .4477-1 1 .44772 1 1 1Zm4 0c-.5523 0-1-.4477-1-1s.4477-1 1-1 1 .4477 1 1-.4477 1-1 1Zm3-1c0 .5523.4477 1 1 1s1-.4477 1-1-.4477-1-1-1-1 .4477-1 1Z"
        />
        <path
          fill={variableColor}
          fillRule="evenodd"
          d="M12 21c4.9706 0 9-4.0294 9-9 0-4.97056-4.0294-9-9-9-4.97056 0-9 4.02944-9 9 0 1.4734.35404 2.864.98168 4.0916l-.68751 3.4375c-.13994.6997.47698 1.3167 1.1767 1.1767l3.43755-.6875C9.13597 20.646 10.5266 21 12 21Zm-5.9206-5.199-.31698-.6199C5.27554 14.2289 5 13.1495 5 12c0-3.86599 3.13401-7 7-7 3.866 0 7 3.13401 7 7 0 3.866-3.134 7-7 7-1.1495 0-2.22886-.2755-3.18109-.7624l-.61995-.317-2.64945.5299.52989-2.6495Z"
          clipRule="evenodd"
        />
      </svg>
    </BaseIcon>
  )
}

export default IconBallonDot
