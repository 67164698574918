import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconRealEstate: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 32 32" title={title}>
      <path
        fill={variableColor}
        fillRule="evenodd"
        d="M10.194 7.335c0-.966.783-1.75 1.75-1.75h15.402a1.75 1.75 0 0 1 1.748 1.832l-.46 9.829a1.75 1.75 0 0 1-1.646 1.665l-1.443.084-.087-1.498 1.443-.083a.25.25 0 0 0 .235-.238l.46-9.829a.25.25 0 0 0-.25-.262H11.944a.25.25 0 0 0-.25.25v1.246H23.92c.996 0 1.79.829 1.749 1.823l-.467 11.171a1.75 1.75 0 0 1-1.748 1.677h-1.238v-1.5h1.238a.25.25 0 0 0 .25-.24l.466-11.17a.25.25 0 0 0-.25-.261H6.86a.25.25 0 0 0-.25.25v1.43h-1.5v-1.43c0-.966.784-1.75 1.75-1.75h3.334V7.335ZM4.656 11.9a1.75 1.75 0 0 0-1.748 1.819l.41 10.437a1.75 1.75 0 0 0 1.749 1.68h15.188a1.75 1.75 0 0 0 1.749-1.68l.41-10.437a1.75 1.75 0 0 0-1.748-1.819H4.656Zm-.25 1.76a.25.25 0 0 1 .25-.26h16.01a.25.25 0 0 1 .25.26l-.41 10.437a.25.25 0 0 1-.25.24H5.065a.25.25 0 0 1-.25-.24l-.41-10.437Zm4.188 3.526a.879.879 0 0 0-.448.749v4.016c0 .497.45.9 1.005.9h7.035c.555 0 1.005-.403 1.005-.9v-4.016c0-.301-.168-.582-.447-.749l-3.518-2.099a1.104 1.104 0 0 0-1.115 0l-3.517 2.1Zm5.343 2.688v1.83h2.016v-3.286l-3.284-2.099-3.215 2.1v3.285h2.156v-1.83h2.327Z"
        clipRule="evenodd"
      />
    </BaseIcon>
  )
}

export default IconRealEstate
