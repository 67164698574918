import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconArea: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        d="M19.875 14.25C19.2537 14.25 18.75 13.7463 18.75 13.125L18.75 5.25L10.875 5.25C10.2537 5.25 9.75 4.74632 9.75 4.125C9.75 3.50368 10.2537 3 10.875 3H19.875C20.4963 3 21 3.50368 21 4.125V13.125C21 13.7463 20.4963 14.25 19.875 14.25Z"
        fill={variableColor}
      />
      <path
        d="M4.125 9.75C4.74632 9.75 5.25 10.2537 5.25 10.875V18.75H13.125C13.7463 18.75 14.25 19.2537 14.25 19.875C14.25 20.4963 13.7463 21 13.125 21H4.125C3.50368 21 3 20.4963 3 19.875V10.875C3 10.2537 3.50368 9.75 4.125 9.75Z"
        fill={variableColor}
      />
      <path
        d="M16.1705 9.42049C16.6098 8.98116 16.6098 8.26884 16.1705 7.82951C15.7312 7.39017 15.0188 7.39017 14.5795 7.82951L7.82951 14.5795C7.39017 15.0188 7.39017 15.7312 7.82951 16.1705C8.26884 16.6098 8.98116 16.6098 9.42049 16.1705L16.1705 9.42049Z"
        fill={variableColor}
      />
    </BaseIcon>
  )
}

export default IconArea
