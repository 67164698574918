export const INTEREST_RATE = 10.5
export const HOUSE_HOLD_PERCENTAGE = 30
export const MINIMUM_ENTRY_PERCENTAGE = 20
export const MAXIMUM_ENTRY_PERCENTAGE = 90
export const DEFAULT_TERM = 30
export const DEFAULT_ENTRY_PERCENTAGE = 20

export const getBalance = (totalValue: number, downPaymentValue: number) =>
  totalValue - downPaymentValue

export const getPercentageByValue = (value: number, total: number) =>
  (value / total) * 100

export const getValueByPercentage = (value: number, percentage: number) =>
  Math.trunc(value * (percentage / 100))

export const getAmortization = (balance: number, years: number) =>
  balance / (years * 12)

export const getInterestRatePower = () =>
  (1 + INTEREST_RATE / 100) ** (1 / 12) - 1

export const getFirstInterestRate = (balance: number) =>
  balance * getInterestRatePower()

export const getLastInterestRate = (amortization: number) =>
  amortization * getInterestRatePower()

export const getFirstPayment = (balance: number, amortization: number) =>
  amortization + getFirstInterestRate(balance)

export const getLastPayment = (amortization: number) =>
  amortization + getLastInterestRate(amortization)

export const getHouseHoldIncome = (firstPayment: number) =>
  (firstPayment * 100) / HOUSE_HOLD_PERCENTAGE
