import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconArrowLeft: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        d="M10.8 4.60006C11.1314 4.15823 11.0418 3.53143 10.6 3.20006C10.1582 2.86869 9.53137 2.95823 9.2 3.40006L3.2 11.4001C2.93333 11.7556 2.93333 12.2445 3.2 12.6001L9.2 20.6001C9.53137 21.0419 10.1582 21.1314 10.6 20.8001C11.0418 20.4687 11.1314 19.8419 10.8 19.4001L6 13.0001L20 13.0001C20.5523 13.0001 21 12.5523 21 12.0001C21 11.4478 20.5523 11.0001 20 11.0001L6 11.0001L10.8 4.60006Z"
        fill={variableColor}
      />
    </BaseIcon>
  )
}

export default IconArrowLeft
