import { PAVLOV_URL } from 'config'
import axios from 'axios'
import { getCookies } from 'cookies-next'
import { getStorageUserGeoData } from 'lib/user'
import { transformCamelCaseKeysToSnakeCase } from 'lib/format'
import { User } from 'graphql/types'
import * as sentry from './sentry'
import { getAmplitude } from './amplitude'

type TLogEventProps = {
  event: string
  props: Record<string, unknown>
  user?: User | null
}

export type TCurrentEvent = {
  event: string
  backends: string[]
}

type TPavlovEventsObject = Record<string, TCurrentEvent>

const amplitudeInstance = getAmplitude()
const amplitudeDeviceId = amplitudeInstance?.getDeviceId()

export const PAVLOV_EVENTS: TPavlovEventsObject = {
  'page-open': {
    event: 'page-open',
    backends: ['CAPI'],
  },
  'listing-detail-open': {
    event: 'listing-detail-open',
    backends: ['CAPI'],
  },
  'listing-detail-open-visit-form': {
    event: 'listing-detail-open-visit-form',
    backends: ['CAPI'],
  },
  'listing-detail-schedule-visit': {
    event: 'listing-detail-schedule-visit',
    backends: ['CAPI'],
  },
  'listing-detail-favorite-listing': {
    event: 'listing-detail-favorite-listing',
    backends: ['CAPI'],
  },
  'listing-detail-favorite-related-listing': {
    event: 'listing-detail-favorite-related-listing',
    backends: ['CAPI'],
  },
  'listing-offer-submit-success': {
    event: 'listing-offer-submit-success',
    backends: ['CAPI'],
  },
  'listing-search-favorite-listing': {
    event: 'listing-search-favorite-listing',
    backends: ['CAPI'],
  },
  'listing-detail-open-interest': {
    event: 'listing-detail-open-interest',
    backends: ['CAPI'],
  },
  'listing-search-results': {
    event: 'listing-search-results',
    backends: ['CAPI'],
  },
  'you-setup-send': {
    event: 'you-setup-send',
    backends: ['CAPI'],
  },
  'you-setup-send-success': {
    event: 'you-setup-send-success',
    backends: ['CAPI'],
  },
  'you-setup-specialist-send-success': {
    event: 'you-setup-specialist-send-success',
    backends: ['CAPI'],
  },
}

const getUserGeoData = () => {
  const user = getStorageUserGeoData()

  return {
    city: user.city,
    state: user.state,
    country: user.country,
    ip_address: user.xForwardedFor?.split(',')[0],
    user_agent: navigator?.userAgent?.replace(/\([^)]*\)\s/, ''),
  }
}

const META_COOKIES = ['_fbp', '_fbc']

export const getMetaProps = () => {
  const cookies = getCookies()
  const metaProps = META_COOKIES.reduce(
    (dict: { [key: string]: string | null }, cookieName) => {
      const value = cookies[cookieName]
      const key = cookieName.replace('_', '')
      if (value) {
        dict[key] = String(value)
      }
      return dict
    },
    {},
  )

  // When fbc cookie not available, fallback to fbclid querystring param
  const fbclid =
    !metaProps.fbc && typeof URLSearchParams !== 'undefined'
      ? new URLSearchParams(window.location?.search || '')?.get('fbclid')
      : null
  if (fbclid) {
    // https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/fbp-and-fbc/
    metaProps.fbc = `fb.1.${new Date().getTime()}.${fbclid}`
  }
  return metaProps
}

export const logEvent = ({ event, props, user }: TLogEventProps) => {
  if (window && Boolean(window.fetch)) {
    const currentEvent = PAVLOV_EVENTS[event]

    if (PAVLOV_URL && currentEvent) {
      // eslint-disable-next-line
      const { eventID, currentUser, userProfile, ...eventProps } = props

      axios
        .post(`${PAVLOV_URL}/api/events/push`, {
          backends: currentEvent.backends,
          event: {
            id: eventID,
            name: currentEvent.event,
            props: eventProps,
            source: 'website',
            url: window.location.href,
          },
          userData: {
            ...transformCamelCaseKeysToSnakeCase(user),
            ...getUserGeoData(),
            ...getMetaProps(),
            external_id: amplitudeDeviceId,
          },
        })
        .catch((error: unknown) => {
          sentry.capture({ error })
        })
    }
  }
}
