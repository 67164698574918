import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconConcierge: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fill={variableColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 6v2h2V6h1.5c.5523 0 1-.44772 1-1s-.4477-1-1-1h-5c-.55228 0-1 .44772-1 1s.44772 1 1 1H11Zm1 4.5c3.0162 0 5.3289 1.8027 5.8764 4H6.12364c.54748-2.1973 2.86021-4 5.87636-4Zm0-2c-4.2681 0-8 2.9949-8 7v1h16v-1c0-4.0051-3.7319-7-8-7ZM4 18c-.55228 0-1 .4477-1 1s.44772 1 1 1h16c.5523 0 1-.4477 1-1s-.4477-1-1-1H4Z"
      />
    </BaseIcon>
  )
}

export default IconConcierge
