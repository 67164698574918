import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconEmCasa: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fill={variableColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="m11.1046 2.93176 8.1963 5.98929L16.17 18.6118H6.03907L2.9082 8.92105l8.1964-5.98929ZM11.1033 0c-.5663 0-1.13262.175951-1.61384.527374L1.13199 6.63461C.169799 7.33769-.233 8.58421.134524 9.72166L3.32688 19.6032c.36753 1.1377 1.42171 1.9082 2.61104 1.9082H16.2686c1.1894 0 2.2436-.7705 2.6111-1.9079l3.1923-9.88184c.3676-1.13745-.035-2.38397-.9974-3.08705L12.7171.527374C12.2359.175951 11.6696 0 11.1033 0Z"
      />
    </BaseIcon>
  )
}

export default IconEmCasa
