import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconChevronUp: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        d="M20.6234 16.9091c-.5021.5021-1.3161.5021-1.8183 0L12 10.104l-6.80515 6.8051c-.5021.5021-1.31617.5021-1.81827 0-.50211-.5021-.50211-1.3161 0-1.8182l7.71432-7.71432c.5021-.50211 1.3161-.50211 1.8182 0l7.7143 7.71432c.5021.5021.5021 1.3161 0 1.8182Z"
        fill={variableColor}
      />
    </BaseIcon>
  )
}

export default IconChevronUp
