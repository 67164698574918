import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconMail: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fill={variableColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 6.8C3 5.80589 3.80589 5 4.8 5h14.4c.9941 0 1.8.80589 1.8 1.8v10.8c0 .9941-.8059 1.8-1.8 1.8H4.8c-.99411 0-1.8-.8059-1.8-1.8V6.8Zm3.07279 0H17.9272l-5.2908 5.2908c-.3515.3515-.9213.3515-1.2728 0L6.07279 6.8ZM4.8 8.07279V17.6h14.4V8.07279l-5.2908 5.29081c-1.0544 1.0544-2.764 1.0544-3.8184 0L4.8 8.07279Z"
      />
    </BaseIcon>
  )
}

export default IconMail
