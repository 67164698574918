import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconSun: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fill={variableColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3c.5523 0 1 .44772 1 1v1c0 .55228-.4477 1-1 1s-1-.44772-1-1V4c0-.55228.4477-1 1-1Zm0 6c-1.6569 0-3 1.3431-3 3s1.3431 3 3 3 3-1.3431 3-3-1.3431-3-3-3Zm-5 3c0-2.76142 2.23858-5 5-5 2.7614 0 5 2.23858 5 5 0 2.7614-2.2386 5-5 5-2.76142 0-5-2.2386-5-5Zm12.2071-5.79289c.3905-.39053.3905-1.02369 0-1.41422-.3905-.39052-1.0237-.39052-1.4142 0l-1 1c-.3905.39053-.3905 1.02369 0 1.41422.3905.39052 1.0237.39052 1.4142 0l1-1ZM8.20711 16.7929c.39052.3905.39052 1.0237 0 1.4142l-1 1c-.39053.3905-1.02369.3905-1.41422 0-.39052-.3905-.39052-1.0237 0-1.4142l1-1c.39053-.3905 1.02369-.3905 1.41422 0Zm-2-12.00001c-.39053-.39052-1.02369-.39052-1.41422 0-.39052.39053-.39052 1.02369 0 1.41422l1 1c.39053.39052 1.02369.39052 1.41422 0 .39052-.39053.39052-1.02369 0-1.41422l-1-1ZM16.7929 16.7929c.3905-.3905 1.0237-.3905 1.4142 0l1 1c.3905.3905.3905 1.0237 0 1.4142-.3905.3905-1.0237.3905-1.4142 0l-1-1c-.3905-.3905-.3905-1.0237 0-1.4142ZM13 19c0-.5523-.4477-1-1-1s-1 .4477-1 1v1c0 .5523.4477 1 1 1s1-.4477 1-1v-1Zm8-7c0 .5523-.4477 1-1 1h-1c-.5523 0-1-.4477-1-1s.4477-1 1-1h1c.5523 0 1 .4477 1 1ZM5 13c.55228 0 1-.4477 1-1s-.44772-1-1-1H4c-.55228 0-1 .4477-1 1s.44772 1 1 1h1Z"
      />
    </BaseIcon>
  )
}

export default IconSun
