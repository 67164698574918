import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconArrowDown: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.60006 13.2c-.44183-.3314-1.06863-.2418-1.4.2-.33137.4418-.24183 1.0686.2 1.4l8.00004 6c.3555.2667.8444.2667 1.2 0l8-6c.4418-.3314.5313-.9582.2-1.4-.3314-.4418-.9582-.5314-1.4-.2l-6.4 4.8V4c0-.55228-.4478-1-1-1-.5523 0-1 .44772-1 1v14l-6.40004-4.8Z"
        fill={variableColor}
      />
    </BaseIcon>
  )
}

export default IconArrowDown
