import { UI_VERSION } from 'config'
import { getAppVersion } from './experiment'
import logger from './logger'

export const INITIAL = 'initial_'
export const KEY_CAMPAIGN = 'utm_campaign'
export const KEY_CONTENT = 'utm_content'
export const KEY_GCLID = 'gclid'
export const KEY_ID = 'utm_id'
export const KEY_MEDIUM = 'utm_medium'
export const KEY_SOURCE = 'utm_source'
export const KEY_TERM = 'utm_term'
export const KEY_EC_UI_VERSION = 'ec_ui_version'

const UTM_KEYS = [
  KEY_CAMPAIGN,
  KEY_CONTENT,
  KEY_GCLID,
  KEY_ID,
  KEY_MEDIUM,
  KEY_SOURCE,
  KEY_TERM,
  KEY_EC_UI_VERSION,
]

/**
 * Saves user utm and initial utm data. If no initial utm keys
 * are set yet, save them. Otherwise, only save the current values.
 */
export const saveUtm = (utm: Array<Record<string, string>>) => {
  const uiVersionKey = {
    [KEY_EC_UI_VERSION]: getAppVersion() || UI_VERSION,
  } as Record<string, string>

  utm.push(uiVersionKey)

  try {
    utm.forEach((item) => {
      const key = Object.keys(item)[0]
      const value = item[key]
      const initial = localStorage?.getItem(`${INITIAL}${key}`)

      if (!initial) {
        localStorage?.setItem(`${INITIAL}${key}`, value)
      }
      localStorage?.setItem(key, value)
    })
  } catch (e) {
    logger.action('site-storage-error', { error: e })
    logger.capture(e)
  }
}

/**
 * Get utm data from query. Returns an array of utm
 * objects with their key/values.
 */
export const getUtmArrayFromUrl = (search: string) => {
  if (search.trim() === '') return []

  const cleanSearch = search.split('?')[1]

  const items = cleanSearch
    .split('&')
    .map((item) => {
      const keyValue = item.split('=')
      return {
        [keyValue[0]]: keyValue[1],
      }
    })
    .filter((item) => {
      const key = Object.keys(item)[0]
      const value = item[key]
      return UTM_KEYS.indexOf(key) !== -1 && value && value.trim() !== ''
    })

  return items
}

/**
 * Get utm data from local storage. Returns an array of utm
 * objects with their key/values.
 */
export const getUtmArrayFromLocalStorage = () => {
  try {
    return [
      { initialCampaign: localStorage.getItem(`${INITIAL}${KEY_CAMPAIGN}`) },
      { initialContent: localStorage.getItem(`${INITIAL}${KEY_CONTENT}`) },
      { initialGclid: localStorage.getItem(`${INITIAL}${KEY_GCLID}`) },
      { initialId: localStorage.getItem(`${INITIAL}${KEY_ID}`) },
      { initialMedium: localStorage.getItem(`${INITIAL}${KEY_MEDIUM}`) },
      { initialSource: localStorage.getItem(`${INITIAL}${KEY_SOURCE}`) },
      { initialTerm: localStorage.getItem(`${INITIAL}${KEY_TERM}`) },
      {
        initialEcUiVersion: localStorage.getItem(
          `${INITIAL}${KEY_EC_UI_VERSION}`,
        ),
      },
      { campaign: localStorage.getItem(KEY_CAMPAIGN) },
      { content: localStorage.getItem(KEY_CONTENT) },
      { gclid: localStorage.getItem(KEY_GCLID) },
      { id: localStorage.getItem(KEY_ID) },
      { medium: localStorage.getItem(KEY_MEDIUM) },
      { source: localStorage.getItem(KEY_SOURCE) },
      { term: localStorage.getItem(KEY_TERM) },
      { ecUiVersion: localStorage.getItem(`${KEY_EC_UI_VERSION}`) },
    ]
  } catch (e) {
    logger.action('site-storage-error', { error: e })
    logger.capture(e)
    return []
  }
}

/**
 * from: [{utm_campaign: 'mkt'}, {utm_source: 'google'}]
 * to:   { campaign: 'mkt', source: 'google' }
 */
export const removeUtmPrefix = (arr: Array<Record<string, unknown>>) =>
  arr.reduce((prev, item) => {
    const key = Object.keys(item)[0]
    const newKey = key.replace('utm_', '')
    return { ...prev, [newKey]: item[key] }
  }, {})

export const getUTMObject = () => {
  const utmFromUrl = getUtmArrayFromUrl(window.location.search)
  const utmArray = !utmFromUrl.length
    ? getUtmArrayFromLocalStorage()
    : utmFromUrl

  const utmObject = removeUtmPrefix(utmArray)

  return {
    initialCampaign: utmObject.initialCampaign ?? null,
    initialContent: utmObject.initialContent ?? null,
    initialGclid: utmObject.initialGclid ?? null,
    initialId: utmObject.initialId ?? null,
    initialMedium: utmObject.initialMedium ?? null,
    initialSource: utmObject.initialSource ?? null,
    initialTerm: utmObject.initialTerm ?? null,
    initialEcUiVersion: utmObject.initialEcUiVersion ?? null,
    campaign: utmObject.campaign ?? null,
    content: utmObject.content ?? null,
    gclid: utmObject.gclid ?? null,
    id: utmObject.id ?? null,
    medium: utmObject.medium ?? null,
    source: utmObject.source ?? null,
    term: utmObject.term ?? null,
    ecUiVersion: utmObject.ecUiVersion ?? null,
  }
}

export const initUtm = () => {
  if (typeof window === 'undefined') return

  const utmArray = getUtmArrayFromUrl(window.location.search)
  saveUtm(utmArray)
}
