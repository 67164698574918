import App from 'next/app'
import { Router, useRouter } from 'next/router'
import { useEffect } from 'react'
import type { AppContext, AppProps } from 'next/app'
import NProgress from 'nprogress'
import { ApolloProvider } from '@apollo/client'
import { getApolloClient } from 'graphql/apolloClient'
import {
  CLOUDFRONT_CITY_HEADER,
  CLOUDFRONT_COUNTRY_HEADER,
  CLOUDFRONT_STATE_HEADER,
  CLOUDFRONT_X_FORWARDED_FOR_HEADER,
  LOCALSTORAGE_USER_KEY,
} from 'config'
import { BACKBUTTON_SESSIONSTORAGE } from './imoveis/imovel/components/BackButton'
import logger from 'lib/logger'
import { initUtm } from 'lib/utm'
import { setPageLoadMetrics } from 'lib/logger/amplitude'
import { TUserGeoData } from 'lib/user'
import { getEmCasaSessionListings } from 'lib/listing'
import 'styles/globals.scss'
import 'nprogress/nprogress.css'

type TAppProps = {
  userGeoData?: TUserGeoData
} & AppProps

const MyApp = ({ Component, pageProps, userGeoData }: TAppProps) => {
  const apolloClient = getApolloClient()
  const { route } = useRouter()

  useEffect(() => {
    logger.init(apolloClient)
  }, [apolloClient])

  useEffect(() => {
    const viewedListings = getEmCasaSessionListings()
    logger.action('page-open', {
      viewedListings,
      totalViewedListings: viewedListings.length,
    })
    NProgress.configure({ showSpinner: false })
    const handleStart = () => {
      NProgress.start()
    }

    const handleStop = () => {
      NProgress.done()
    }

    if (
      route !== '/imoveis/imovel' &&
      route !== '/recomendacoes' &&
      !sessionStorage.getItem(BACKBUTTON_SESSIONSTORAGE)
    ) {
      sessionStorage.setItem(BACKBUTTON_SESSIONSTORAGE, 'done')
    }

    Router.events.on('routeChangeStart', handleStart)
    Router.events.on('routeChangeComplete', handleStop)
    Router.events.on('routeChangeError', handleStop)

    return () => {
      Router.events.off('routeChangeStart', handleStart)
      Router.events.off('routeChangeComplete', handleStop)
      Router.events.off('routeChangeError', handleStop)
    }
  }, [route])

  useEffect(() => {
    initUtm()
    setPageLoadMetrics()
    if (typeof window !== 'undefined' && userGeoData)
      localStorage?.setItem(LOCALSTORAGE_USER_KEY, JSON.stringify(userGeoData))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ApolloProvider client={apolloClient}>
      <Component {...pageProps} />
    </ApolloProvider>
  )
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const pageProps = await App.getInitialProps(appContext)
  const { headers } = appContext.ctx.req || {}

  const userGeoData = {
    city: decodeURIComponent(
      (headers?.[CLOUDFRONT_CITY_HEADER] as string) || '',
    ),
    state: decodeURIComponent(
      (headers?.[CLOUDFRONT_STATE_HEADER] as string) || '',
    ),
    country: decodeURIComponent(
      (headers?.[CLOUDFRONT_COUNTRY_HEADER] as string) || '',
    ),
    xForwardedFor: decodeURIComponent(
      (headers?.[CLOUDFRONT_X_FORWARDED_FOR_HEADER] as string) || '',
    ),
  }

  return {
    ...pageProps,
    userGeoData,
  }
}

export default MyApp
