import { getCookie, getCookies } from 'cookies-next'
// eslint-disable-next-line lodash/import-scope
import { Dictionary } from 'lodash'
import pickBy from 'lodash/pickBy'

export const EXPERIMENT_PREFIX = 'EmCasa-Experiment-'

export const getActiveExperiments = () => {
  const cookies = getCookies()

  return pickBy(cookies, (_, key) => key.startsWith(EXPERIMENT_PREFIX))
}

export const getActiveExperimentsList = () => {
  const experiments = getActiveExperiments() as Dictionary<string>

  return Object.keys(experiments).map((key) => `${key}|${experiments[key]}`)
}

export const getAppVersion = () => {
  const uiVersionMobile = getCookie('uiVersionWebview') as string

  return uiVersionMobile
}

export const getAppDeviceId = () => {
  const appDeviceId = getCookie('appDeviceId') as string

  return appDeviceId
}
