import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconShareIos: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fill={variableColor}
        fillRule="evenodd"
        d="M16.2929 8.70711c.3905.39052 1.0237.39052 1.4142 0 .3905-.39053.3905-1.02369 0-1.41422l-5-5c-.3905-.39052-1.0237-.39052-1.4142 0l-5.00001 5c-.39052.39053-.39052 1.02369 0 1.41422.39053.39052 1.02369.39052 1.41422 0L11 5.41421V14c0 .5523.4477 1 1 1s1-.4477 1-1V5.41421l3.2929 3.2929ZM4 11c-.55228 0-1 .4477-1 1v9c0 .5523.44772 1 1 1h16c.5523 0 1-.4477 1-1v-9c0-.5523-.4477-1-1-1h-5c-.5523 0-1 .4477-1 1s.4477 1 1 1h4v7H5v-7h4c.55228 0 1-.4477 1-1s-.44772-1-1-1H4Z"
        clipRule="evenodd"
      />
    </BaseIcon>
  )
}

export default IconShareIos
