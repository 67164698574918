import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconChevronDown: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        d="M3.37658 7.37658c.5021-.5021 1.31617-.5021 1.81827 0L12 14.1817l6.8051-6.80512c.5022-.50211 1.3162-.50211 1.8183 0 .5021.5021.5021 1.31617 0 1.81827l-7.7143 7.71425c-.5021.5021-1.3161.5021-1.8182 0L3.37658 9.19485c-.50211-.5021-.50211-1.31617 0-1.81827Z"
        fill={variableColor}
      />
    </BaseIcon>
  )
}

export default IconChevronDown
