import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconCar: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fill={variableColor}
        d="M9.75 14.25c0 .6213-.50368 1.125-1.125 1.125S7.5 14.8713 7.5 14.25s.50368-1.125 1.125-1.125 1.125.5037 1.125 1.125Zm6.75 0c0 .6213-.5037 1.125-1.125 1.125s-1.125-.5037-1.125-1.125.5037-1.125 1.125-1.125 1.125.5037 1.125 1.125Z"
      />
      <path
        fill={variableColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 9.75c0 .368-.1767.6947-.4499.9.2825.376.4499.8435.4499 1.35v6.75h-2.25v1.125c0 .6213-.5037 1.125-1.125 1.125s-1.125-.5037-1.125-1.125V18.75h-9v1.125C7.5 20.4963 6.99632 21 6.375 21s-1.125-.5037-1.125-1.125V18.75H3V12c0-.5065.16738-.974.44985-1.35C3.17669 10.4447 3 10.118 3 9.75c0-.62132.50368-1.125 1.125-1.125.23162 0 .44689.07.62582.18999L5.9199 5.30773C6.37929 3.92958 7.66901 3 9.12171 3h5.75659c1.4527 0 2.7424.92958 3.2018 2.30773l1.1691 3.50726c.1789-.11999.3942-.18999.6258-.18999.6213 0 1.125.50368 1.125 1.125ZM8.05444 6.01924c.15313-.45938.58303-.76924 1.06727-.76924h5.75659c.4842 0 .9141.30986 1.0673.76924L17.1891 9.75H6.81085l1.24359-3.73076ZM18.75 12.0003 18.7237 12H5.27626l-.02626.0003V16.5h13.5v-4.4997Z"
      />
    </BaseIcon>
  )
}

export default IconCar
