import {
  DEFAULT_CITY_SLUG,
  DEFAULT_STATE_SLUG,
  GOOGLE_MAPS_API_KEY,
  GOOGLE_MAPS_GEOCODE_URL,
  LOCALSTORAGE_LOCATION_KEY,
} from 'config'
import { slugify } from './text'

export type TLocation = {
  city?: string
  citySlug?: string
  coordinates?: {
    lat: number
    lng: number
  }
  state?: string
  stateSlug?: string
  neighborhood?: string
  street?: string
  streetSlug?: string
  postalCode?: string
}

type TGeocodeResult = google.maps.GeocoderResult
type TJSONResponse = {
  results?: Array<TGeocodeResult>
  error?: { message: string }
}

export const fetchGeocodeByCoordinates = async ({
  lat,
  lng,
}: google.maps.LatLngLiteral) => {
  const response = await fetch(
    `${GOOGLE_MAPS_GEOCODE_URL}?latlng=${lat},${lng}&sensor=false&key=${GOOGLE_MAPS_API_KEY}`,
  )

  const { results, error } = (await response.json()) as TJSONResponse
  if (response.ok) {
    const placeGeocode = results?.[0]
    if (placeGeocode) {
      return Promise.resolve(placeGeocode)
    }
    return Promise.reject(
      new Error(`Erro ao encontrar endereço em ${lat}, ${lng}`),
    )
  }
  return Promise.reject(error)
}

export const fetchGeocodeByAddress = async (address: string) => {
  if (!address) return null

  const response = await fetch(
    `${GOOGLE_MAPS_GEOCODE_URL}?address=${address}&sensor=false&key=${GOOGLE_MAPS_API_KEY}`,
  )

  const { results, error } = (await response.json()) as TJSONResponse
  if (response.ok) {
    const placeGeocode = results?.[0]
    if (placeGeocode) {
      return Promise.resolve(placeGeocode)
    }
    return Promise.reject(new Error(`Erro ao encontrar endereço em ${address}`))
  }
  return Promise.reject(error)
}

export const findAddressComponent = (
  type: string,
  {
    address_components = [] /* eslint-disable-line camelcase, @typescript-eslint/naming-convention */,
  }: { address_components: TGeocodeResult['address_components'] },
) => {
  /* eslint-disable-next-line camelcase */
  return address_components.find(({ types }) => types.includes(type))
}

export const parseGeocode = (geocode: TGeocodeResult): TLocation => {
  try {
    const state = (
      findAddressComponent('administrative_area_level_1', geocode) || {}
    ).short_name
    const city = (
      findAddressComponent('administrative_area_level_2', geocode) || {}
    ).short_name
    const postalCode = (findAddressComponent('postal_code', geocode) || {})
      .short_name
    const citySlug = city ? slugify(city.toLowerCase()) : DEFAULT_CITY_SLUG
    const stateSlug = state?.toLowerCase() || DEFAULT_STATE_SLUG
    const neighborhood = findAddressComponent(
      'sublocality_level_1',
      geocode,
    )?.long_name
    const neighborhoodFallback = findAddressComponent(
      'administrative_area_level_4',
      geocode,
    )?.long_name
    const street = findAddressComponent('route', geocode)?.long_name
    const streetSlug = street ? slugify(street.toLowerCase()) : undefined

    let lat
    let lng

    if (typeof geocode.geometry.location.toJSON === 'function') {
      lat = geocode.geometry.location.toJSON().lat
      lng = geocode.geometry.location.toJSON().lng
    } else {
      lat = geocode.geometry.location.lat
      lng = geocode.geometry.location.lng
    }

    return {
      city,
      citySlug,
      state,
      stateSlug,
      neighborhood: neighborhood || neighborhoodFallback,
      street,
      streetSlug,
      coordinates: { lat: lat as number, lng: lng as number },
      postalCode,
    }
  } catch {
    return {}
  }
}

export const parseLocation = (location: string) => {
  if (!location) return {}

  const [lng, lat, ...rest] = location.split(',')
  return { lng: lng.trim(), lat: lat.trim(), address: rest.join().trim() }
}

export const getStorageCurrentLocation = (): TLocation => {
  if (typeof window !== 'undefined')
    return JSON.parse(
      localStorage?.getItem(LOCALSTORAGE_LOCATION_KEY) || '{}',
    ) as TLocation

  return {}
}
