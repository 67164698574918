import isPlainObject from 'lodash/isPlainObject'
import isArray from 'lodash/isArray'
import snakeCase from 'lodash/snakeCase'

type TTransformCamelCaseKeysToSnakeCase =
  | Record<string, unknown>
  | Record<string, unknown>[]
  | null
  | undefined

export const transformCamelCaseKeysToSnakeCase = (
  obj: TTransformCamelCaseKeysToSnakeCase,
): TTransformCamelCaseKeysToSnakeCase => {
  if (!obj) return obj

  if (isPlainObject(obj)) {
    const newObj: Record<string, unknown> = {}

    Object.keys(obj).forEach((key) => {
      newObj[snakeCase(key)] = transformCamelCaseKeysToSnakeCase(
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        obj[key],
      )
    })

    return newObj
  }

  if (isArray(obj)) {
    return obj.map((item) =>
      transformCamelCaseKeysToSnakeCase(item),
    ) as TTransformCamelCaseKeysToSnakeCase
  }

  return obj
}
