import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconOrder: FC<TIconProps> = ({ color, width, height, title }) => {
  const hexColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fill={hexColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 5c.55228 0 1 .44772 1 1v11c0 .5523-.44771 1-1 1-.55228 0-1-.4477-1-1V6c0-.55228.44772-1 1-1Zm6 9c0-.5523.4477-1 1-1h7c.5523 0 1 .4477 1 1s-.4477 1-1 1h-7c-.5523 0-1-.4477-1-1Zm0 4c0-.5523.4477-1 1-1h7c.5523 0 1 .4477 1 1s-.4477 1-1 1h-7c-.5523 0-1-.4477-1-1Zm-3-8c0-.55228.4477-1 1-1h10c.5523 0 1 .44771 1 1 0 .5523-.4477 1-1 1H11c-.5523 0-1-.4477-1-1Zm0-4c0-.55228.4477-1 1-1h10c.5523 0 1 .44771 1 1 0 .55228-.4477 1-1 1H11c-.5523 0-1-.44771-1-1Z"
      />
      <path
        fill={hexColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.70711 18.1913c-.39053.3906-1.02369.3906-1.41422 0l-3.24173-3.2417c-.39053-.3905-.39053-1.0237 0-1.4142.39052-.3905 1.02369-.3905 1.41421 0L7 16.07l2.53463-2.5346c.39052-.3905 1.02367-.3905 1.41417 0 .3906.3905.3906 1.0237 0 1.4142l-3.24169 3.2417Z"
      />
    </BaseIcon>
  )
}

export default IconOrder
