import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconCircleStrip: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 12c0 4.9706-4.0294 9-9 9-4.97056 0-9-4.0294-9-9 0-4.97056 4.02944-9 9-9 4.9706 0 9 4.02944 9 9ZM7.125 11C6.50368 11 6 11.4477 6 12s.50368 1 1.125 1h9.75c.6213 0 1.125-.4477 1.125-1s-.5037-1-1.125-1h-9.75Z"
        fill={variableColor}
      />
    </BaseIcon>
  )
}

export default IconCircleStrip
