import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconQuestionMark: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7966 2L11.7982 2C13.3316 2.00123 14.8039 2.60467 15.8913 3.68102C16.9791 4.75771 17.5928 6.21984 17.5944 7.74704L17.5944 7.74809C17.5944 10.4043 15.7185 12.2507 13.6977 13.1728C13.3289 13.3417 13.1734 13.492 13.0979 13.5948C13.031 13.6859 12.9789 13.8049 12.945 14.0114V16C12.945 16.5523 12.4973 17 11.945 17C11.3927 17 10.945 16.5523 10.945 16V13.87L10.9541 13.803C11.0154 13.3519 11.152 12.866 11.4855 12.4115C11.8167 11.9602 12.2821 11.6213 12.8654 11.3542L12.8667 11.3536C14.4781 10.6186 15.5943 9.33564 15.5944 7.7483C15.5931 6.75769 15.1951 5.80596 14.4844 5.10247C13.7733 4.39863 12.8071 4.00101 11.7973 4C10.7873 4.00094 9.8209 4.39867 9.10971 5.10276C8.39867 5.8067 8.00074 6.75909 8 7.75023C7.99959 8.30252 7.55154 8.7499 6.99926 8.74949C6.44697 8.74908 5.99959 8.30103 6 7.74874C6.00114 6.22117 6.61468 4.75854 7.7026 3.68147C8.79019 2.60474 10.2628 2.00112 11.7966 2Z"
        fill={variableColor}
      />
      <path
        d="M13 20C13 20.5523 12.5523 21 12 21C11.4477 21 11 20.5523 11 20C11 19.4477 11.4477 19 12 19C12.5523 19 13 19.4477 13 20Z"
        fill={variableColor}
      />
    </BaseIcon>
  )
}

export default IconQuestionMark
