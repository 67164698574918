import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconHealth: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fill={variableColor}
        fillRule="evenodd"
        d="M8 4c0-.55228.44772-1 1-1h6c.5523 0 1 .44772 1 1v4h4c.5523 0 1 .44772 1 1v6c0 .5523-.4477 1-1 1h-4v4c0 .5523-.4477 1-1 1H9c-.55228 0-1-.4477-1-1v-4H4c-.55228 0-1-.4477-1-1V9c0-.55228.44771-1 1-1h4V4Zm1.99638 1h4.00002v4.9996H19v4h-5.0036V19H9.99638v-5.0004H5v-4h4.99638V5Z"
        clipRule="evenodd"
      />
    </BaseIcon>
  )
}

export default IconHealth
