import { FC } from 'react'
import { TBaseIconProps } from './types'

const BaseIcon: FC<TBaseIconProps> = ({
  children,
  width,
  height,
  viewBox,
  title,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      data-testid="baseIcon"
    >
      <title>{title}</title>
      {children}
    </svg>
  )
}

export default BaseIcon
