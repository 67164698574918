import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconBook: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fill={variableColor}
        fillRule="evenodd"
        d="M5.5 4c0-.82843.67157-1.5 1.5-1.5h10c.8284 0 1.5.67157 1.5 1.5v13c0 .8284-.6716 1.5-1.5 1.5h-5.25v2c0 .2411-.1159.4675-.3115.6084-.1955.141-.447.1794-.6757.1031L9.5 20.7906l-1.26283.4209c-.22871.0763-.48013.0379-.6757-.1031-.19558-.1409-.31147-.3673-.31147-.6084v-2H7c-.82843 0-1.5-.6716-1.5-1.5V4Zm3.25 14.5v.9594l.51283-.1709c.15395-.0513.32039-.0513.47434 0l.51283.1709V18.5h-1.5ZM8.5 17H7V4h10v13H8.5Zm0-10c0-.27614.22386-.5.5-.5h6c.2761 0 .5.22386.5.5v2c0 .27614-.2239.5-.5.5H9c-.27614 0-.5-.22386-.5-.5V7Zm1 .5v1h5v-1h-5Zm-1 4.5c0-.2761.22386-.5.5-.5h6c.2761 0 .5.2239.5.5s-.2239.5-.5.5H9c-.27614 0-.5-.2239-.5-.5Zm.5 1.5c-.27614 0-.5.2239-.5.5s.22386.5.5.5h6c.2761 0 .5-.2239.5-.5s-.2239-.5-.5-.5H9Z"
        clipRule="evenodd"
      />
    </BaseIcon>
  )
}

export default IconBook
