import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconPin: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.419 18.8421C12.419 18.8421 19.7874 14.8947 19.7874 9.36842C19.7874 5.29474 16.4927 2 12.419 2C8.34531 2 5.05058 5.29474 5.05058 9.36842C5.05058 14.8947 12.419 18.8421 12.419 18.8421ZM12.419 4C9.44988 4 7.05058 6.39931 7.05058 9.36842C7.05058 11.2547 8.34608 13.1185 10.0814 14.6989C10.906 15.4499 11.7394 16.0535 12.3702 16.4703L12.419 16.5024L12.4678 16.4703C13.0986 16.0535 13.932 15.4499 14.7565 14.6989C16.4919 13.1185 17.7874 11.2547 17.7874 9.36842C17.7874 6.39931 15.3881 4 12.419 4ZM12.419 11.4737C13.5817 11.4737 14.5243 10.5311 14.5243 9.36842C14.5243 8.20572 13.5817 7.26316 12.419 7.26316C11.2563 7.26316 10.3137 8.20572 10.3137 9.36842C10.3137 10.5311 11.2563 11.4737 12.419 11.4737ZM5.05263 19.8947C4.47128 19.8947 4 20.366 4 20.9474C4 21.5287 4.47128 22 5.05263 22H19.7895C20.3708 22 20.8421 21.5287 20.8421 20.9474C20.8421 20.366 20.3708 19.8947 19.7895 19.8947H5.05263Z"
        fill={variableColor}
      />
    </BaseIcon>
  )
}

export default IconPin
