import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconGroceries: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fill={variableColor}
        fillRule="evenodd"
        d="M14.2857 8.14286c0 .56806.4605 1.02857 1.0286 1.02857.568 0 1.0286-.46051 1.0286-1.02857V6.08571c0-.88457-.5154-1.67574-1.2242-2.20707C14.3913 3.33335 13.3965 3 12.2286 3s-2.1628.33335-2.89019.87863c-.7088.53134-1.22413 1.32251-1.22413 2.20709l.00001 2.05714c0 .56806.4605 1.02857 1.02857 1.02857.56806 0 1.02854-.46051 1.02854-1.02857V6.08571c0-.06343.0537-.30083.4009-.56107.3285-.24628.8766-.4675 1.6563-.4675.7797 0 1.3277.22122 1.6562.4675.3472.26023.4009.49764.4009.56107v2.05715ZM6.05714 18.9429v-7.2H18.4v7.2H6.05714ZM4 10.7143c0-.5681.46051-1.02859 1.02857-1.02859H19.4286c.568 0 1.0285.46049 1.0285 1.02859v8.2286C20.4571 20.079 19.5361 21 18.4 21H6.05714C4.92101 21 4 20.079 4 18.9429v-8.2286Zm6.1714 2.8286c0 .426-.34535.7714-.7714.7714s-.77143-.3454-.77143-.7714c0-.4261.34538-.7715.77143-.7715.42605 0 .7714.3454.7714.7715Zm5.1429.7714c.426 0 .7714-.3454.7714-.7714 0-.4261-.3454-.7715-.7714-.7715-.4261 0-.7714.3454-.7714.7715 0 .426.3453.7714.7714.7714Z"
        clipRule="evenodd"
      />
    </BaseIcon>
  )
}

export default IconGroceries
