import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const InfoCircle: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 18 18" title={title}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM10 4C10 4.55228 9.55228 5 9 5C8.44771 5 8 4.55228 8 4C8 3.44772 8.44771 3 9 3C9.55228 3 10 3.44772 10 4ZM8 7C8 6.44772 8.44771 6 9 6C9.55228 6 10 6.44772 10 7V14C10 14.5523 9.55228 15 9 15C8.44771 15 8 14.5523 8 14V7Z"
        fill={variableColor}
      />
    </BaseIcon>
  )
}

export default InfoCircle
