import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconInfo: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 4 21" title={title}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.10156 20.1C1.27314 20.1 0.601563 19.4285 0.601563 18.6L0.601564 7.60004C0.601564 6.77161 1.27314 6.10004 2.10156 6.10004C2.92999 6.10004 3.60156 6.77161 3.60156 7.60004L3.60156 18.6C3.60156 19.4285 2.92999 20.1 2.10156 20.1Z"
        fill={variableColor}
      />
      <path
        d="M0.601564 2.10004C0.601564 1.27161 1.27314 0.600036 2.10156 0.600036C2.92999 0.600037 3.60156 1.27161 3.60156 2.10004C3.60156 2.92846 2.92999 3.60004 2.10156 3.60004C1.27314 3.60004 0.601564 2.92846 0.601564 2.10004Z"
        fill={variableColor}
      />
    </BaseIcon>
  )
}

export default IconInfo
