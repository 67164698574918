import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconDocCheck: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fill={variableColor}
        fillRule="evenodd"
        d="M7.27273 4.63636v6.82484C5.36605 12.1351 4 13.9535 4 16.0909 4 18.8021 6.19787 21 8.90909 21c1.45391 0 2.76021-.632 3.65911-1.6364h6.1591c.9037 0 1.6363-.7326 1.6363-1.6363V7.83344c0-.47187-.2037-.92076-.5588-1.23149L16.151 3.40487C15.8527 3.14387 15.4698 3 15.0735 3H8.90909c-.90374 0-1.63636.73263-1.63636 1.63636Zm7.36367 0H8.90909v6.54544c2.71121 0 4.90911 2.1979 4.90911 4.9091 0 .5738-.0985 1.1246-.2794 1.6364h5.1885V8.72727h-4.0909V4.63636ZM11.6428 14.7536c.1651-.1541.1741-.413.0199-.5782-.1542-.1652-.413-.1741-.5782-.0199l-3.22736 3.0122-1.12346-1.0486c-.16518-.1541-.42404-.1452-.5782.02-.15416.1651-.14524.424.01994.5781l1.40259 1.3091c.15718.1467.40109.1467.55826 0l3.50653-3.2727Z"
        clipRule="evenodd"
      />
    </BaseIcon>
  )
}

export default IconDocCheck
