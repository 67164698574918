import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconShine: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m16.5677 7.60634 1.007 1.67824c.1942.32367.6633.32367.8575 0l1.0069-1.67824c.0422-.07037.1011-.12927.1715-.1715l1.5285-.91707c.3545-.21274.3123-.73942-.0716-.89298l-1.3876-.55504c-.1137-.04547-.2067-.1311-.2615-.24063l-.9678-1.93554c-.1842-.36852-.7101-.36852-.8944 0l-.9678 1.93554c-.0547.10953-.1478.19516-.2615.24063l-1.3876.55504c-.3839.15356-.4261.68024-.0715.89298l1.5284.91707c.0704.04223.1293.10113.1715.1715ZM7.06774 15.1063l2.50694 4.1783c.19421.3236.66332.3236.85752 0l2.5069-4.1783c.0422-.0703.1011-.1292.1715-.1715l4.0285-2.417c.3545-.2128.3123-.7394-.0716-.893l-3.8876-1.555c-.1137-.0455-.2067-.13115-.2615-.24068l-2.4678-4.93554c-.1842-.36852-.71012-.36852-.89438 0L7.08845 9.82912c-.05477.10953-.14782.19518-.26152.24068l-3.88758 1.555c-.38392.1536-.42612.6802-.07156.893l4.02845 2.417c.07038.0423.12928.1012.1715.1715Zm10.50696 6.1783-1.007-1.6783c-.0422-.0703-.1011-.1292-.1715-.1715l-1.5284-.917c-.3546-.2128-.3124-.7394.0715-.893l1.3876-.555c.1137-.0455.2068-.1311.2615-.2407l.9678-1.9355c.1843-.3685.7102-.3685.8944 0l.9678 1.9355c.0548.1096.1478.1952.2615.2407l1.3876.555c.3839.1536.4261.6802.0716.893l-1.5285.917c-.0704.0423-.1293.1012-.1715.1715l-1.0069 1.6783c-.1942.3236-.6633.3236-.8575 0Z"
        fill={variableColor}
      />
    </BaseIcon>
  )
}

export default IconShine
