import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconLink: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fill={variableColor}
        d="M3 11.5C3 9.01472 5.01472 7 7.5 7h2.7c.4971 0 .9.40294.9.9s-.4029.9-.9.9H7.5c-1.49117 0-2.7 1.2088-2.7 2.7s1.20883 2.7 2.7 2.7h2.7c.4971 0 .9.4029.9.9 0 .4971-.4029.9-.9.9H7.5C5.01472 16 3 13.9853 3 11.5Zm18 0C21 9.01472 18.9853 7 16.5 7h-2.7c-.4971 0-.9.40294-.9.9s.4029.9.9.9h2.7c1.4912 0 2.7 1.2088 2.7 2.7s-1.2088 2.7-2.7 2.7h-2.7c-.4971 0-.9.4029-.9.9 0 .4971.4029.9.9.9h2.7c2.4853 0 4.5-2.0147 4.5-4.5Z"
      />
      <path
        fill={variableColor}
        d="M9.3 10.6c-.49706 0-.9.4029-.9.9 0 .4971.40294.9.9.9h5.4c.4971 0 .9-.4029.9-.9 0-.4971-.4029-.9-.9-.9H9.3Z"
      />
    </BaseIcon>
  )
}

export default IconLink
