import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconSpin: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fill={variableColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4325 14.9801c.2202-.4237.6367-.7301 1.1142-.7301.7362 0 1.2741.6985.9684 1.3682C19.069 18.7871 15.6835 21 12 21c-4.97056 0-9-4.0294-9-9 0-4.97056 4.02944-9 9-9 2.4754 0 4.7376.99935 6.3834 2.61661l.6961-.69611C19.7882 4.21178 21 4.71372 21 5.71599V9.75c0 .6213-.5037 1.125-1.125 1.125h-4.034c-1.0023 0-1.5042-1.21178-.7955-1.92049l1.7544-1.75445C15.5551 5.99441 13.8502 5.25 12 5.25c-3.72792 0-6.75 3.02208-6.75 6.75 0 3.7279 3.02208 6.75 6.75 6.75 2.6582 0 5.2717-1.5366 6.4325-3.7699Z"
      />
    </BaseIcon>
  )
}

export default IconSpin
