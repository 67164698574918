import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconBuilding: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fill={variableColor}
        d="M10.4 6.6H8.6v1.8h1.8V6.6Zm5.4 0H14v1.8h1.8V6.6ZM14 10.2h1.8V12H14v-1.8Zm-3.6 0H8.6V12h1.8v-1.8Zm-1.8 3.6h1.8v1.8H8.6v-1.8Zm7.2 0H14v1.8h1.8v-1.8Z"
      />
      <path
        fill={variableColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.8 3C5.80589 3 5 3.80589 5 4.8V21h14.4V4.8c0-.99411-.8059-1.8-1.8-1.8H6.8Zm10.8 1.8H6.8v14.4h3.6v-2.7H14v2.7h3.6V4.8Z"
      />
    </BaseIcon>
  )
}

export default IconBuilding
