import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconBell: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fill={variableColor}
        fillRule="evenodd"
        d="M13.536 5c0 .113-.009.224-.027.332A6.002 6.002 0 0 1 17.536 11v3.837c0 .104.042.204.116.278l.906.907c1.1 1.099.32 2.978-1.233 2.978H5.748c-1.554 0-2.333-1.88-1.234-2.978l.907-.907a.393.393 0 0 0 .115-.278V11a6.003 6.003 0 0 1 4.028-5.668A2 2 0 1 1 13.537 5Zm2.701 11.53a2.393 2.393 0 0 1-.7-1.693V11a4 4 0 0 0-8 0v3.837c0 .635-.253 1.244-.702 1.692l-.47.471h10.343l-.47-.47Z"
        clipRule="evenodd"
      />
      <path
        fill={variableColor}
        d="m9.54 19 2 .002 2-.002c0 1-1 2-2 2s-2-1-2-2Z"
      />
    </BaseIcon>
  )
}

export default IconBell
