import { City } from 'graphql/types'

export const orderCitiesByVolume = (cities?: Array<City>) => {
  if (!cities || !cities.length) return []

  const order = [
    'sao-paulo',
    'rio-de-janeiro',
    'santo-andre',
    'sao-jose-dos-campos',
    'campinas',
    'sao-bernardo-do-campo',
    'osasco',
    'guarulhos',
  ]

  return [...cities].sort((a, b) => {
    const indexA = order.indexOf(a.nameSlug as string)
    const indexB = order.indexOf(b.nameSlug as string)

    if (indexA >= 0 && indexB >= 0) {
      return indexA - indexB
    } else if (indexA >= 0) {
      return -1
    } else if (indexB >= 0) {
      return 1
    } else {
      return 0
    }
  })
}
