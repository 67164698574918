import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconHeartFilled: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        d="M3 8.95C3 12.352 7.365 15.997 12 20.2C16.635 15.988 21 12.352 21 8.95C21 6.178 18.822 4 16.05 4C14.484 4 12.981 4.729 12 5.881C11.019 4.729 9.516 4 7.95 4C5.178 4 3 6.178 3 8.95Z"
        fill={variableColor}
      />
    </BaseIcon>
  )
}

export default IconHeartFilled
