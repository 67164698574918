import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconHouse: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fill={variableColor}
        clipRule="evenodd"
        fillRule="evenodd"
        d="M3 9.66667c0-.66871.3342-1.29317.8906-1.6641l7-4.66667c.6718-.44787 1.547-.44787 2.2188 0l7 4.66667c.5564.37093.8906.99539.8906 1.6641v8.92963c0 1.1046-.8954 2-2 2H5c-1.10457 0-2-.8954-2-2V9.66667Zm11 8.92963v-4h-4v4H5V9.66667L12 5l7 4.66667v8.92963h-5Z"
      />
    </BaseIcon>
  )
}

export default IconHouse
