import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconCopy: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fill={variableColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 17.4h9.6c1.3255 0 2.4-1.0745 2.4-2.4V5.4C21 4.07452 19.9255 3 18.6 3H9C7.67452 3 6.6 4.07452 6.6 5.4V15c0 1.3255 1.07452 2.4 2.4 2.4Zm9.6-2.4H9V5.4h9.6V15Z"
      />
      <path
        fill={variableColor}
        d="M5.4 17.4V7.8H3v9.6C3 19.3882 4.61177 21 6.6 21H15v-2.4H6.6c-.66274 0-1.2-.5373-1.2-1.2Z"
      />
    </BaseIcon>
  )
}

export default IconCopy
