import { TFieldsProps, TFilterItemProps, TFiltersProps } from './types'
// Using SUBWAY_DISTANCE or MIN_PRICE from 'lib/search' is causing the following error:
// Server Error
// ReferenceError: Cannot access 'SUBWAY_DISTANCE'/'MIN_PRICE' before initialization
// This error happened while generating the page. Any console logs will be displayed in the terminal window.

export const sunPeriodsItem: TFilterItemProps = {
  fullWidthDesktop: true,
  formElement: {
    title: {
      variant: 'label',
      value: 'Iluminação do sol',
    },
    size: 'big',
  },
  filter: {
    variant: 'checkbox',
    items: [
      {
        label: 'Manhã',
        inputElementProps: {
          value: 'MORNING',
          name: 'sunPeriods',
        },
      },
      {
        label: 'Tarde',
        inputElementProps: {
          value: 'EVENING',
          name: 'sunPeriods',
        },
      },
    ],
  },
}

export const infrastructureItem: TFilterItemProps = {
  fullWidthDesktop: true,
  formElement: {
    title: {
      variant: 'label',
      value: 'Infraestrutura',
    },
    size: 'big',
  },
  filter: {
    variant: 'checkbox',
    items: [],
  },
}

export const realtyItem: TFilterItemProps = {
  fullWidthDesktop: true,
  formElement: {
    title: {
      variant: 'label',
      value: 'Detalhes do imóvel',
    },
    size: 'big',
  },
  filter: {
    variant: 'checkbox',
    items: [],
  },
}

export const conciergeItem: TFilterItemProps = {
  fullWidthDesktop: true,
  formElement: {
    title: {
      variant: 'label',
      value: 'Portaria',
    },
    size: 'big',
  },
  filter: {
    variant: 'checkbox',
    items: [],
  },
}

export const filters: TFiltersProps = [
  {
    fullWidthDesktop: true,
    formElement: {
      title: {
        variant: 'label',
        value: 'Tipo de imóvel',
      },
      size: 'big',
    },
    filter: {
      variant: 'checkbox',
      inlineDesktop: true,
      items: [
        {
          label: 'Apartamento',
          inputElementProps: {
            value: 'Apartamento',
            name: 'types',
          },
        },
        {
          label: 'Casa',
          inputElementProps: {
            value: 'Casa',
            name: 'types',
          },
        },
        {
          label: 'Cobertura',
          inputElementProps: {
            value: 'Cobertura',
            name: 'types',
          },
        },
      ],
    },
  },
  {
    formElement: {
      title: {
        variant: 'label',
        value: 'Preço',
      },
      helpText: { value: 'Valor mínimo de R$ 400.000,00' },
      size: 'big',
      validateRange: {
        value: true,
        group: 'price',
      },
    },
    filter: {
      variant: 'input',
      items: [
        {
          formElement: {
            title: {
              variant: 'label',
              value: 'Mínimo',
              htmlFor: 'minPrice',
            },
          },
          input: {
            leadingContent: {
              variant: 'text',
              value: 'R$',
            },
            inputElementProps: {
              name: 'minPrice',
              id: 'minPrice',
              inputMode: 'numeric',
              maxLength: 11,
            },
          },
        },
        {
          formElement: {
            title: {
              variant: 'label',
              value: 'Máximo',
              htmlFor: 'maxPrice',
            },
          },
          input: {
            leadingContent: {
              variant: 'text',
              value: 'R$',
            },
            inputElementProps: {
              name: 'maxPrice',
              id: 'maxPrice',
              inputMode: 'numeric',
              maxLength: 11,
            },
          },
        },
      ],
    },
  },
  {
    formElement: {
      title: {
        variant: 'label',
        value: 'Área do imóvel',
      },
      size: 'big',
      validateRange: {
        value: true,
        group: 'area',
      },
    },
    filter: {
      variant: 'input',
      items: [
        {
          formElement: {
            title: {
              variant: 'label',
              value: 'Mínima',
              htmlFor: 'minArea',
            },
          },
          input: {
            trailingContent: {
              variant: 'text',
              value: 'm²',
            },
            inputElementProps: {
              name: 'minArea',
              id: 'minArea',
              inputMode: 'numeric',
              maxLength: 6,
            },
          },
        },
        {
          formElement: {
            title: {
              variant: 'label',
              value: 'Máxima',
              htmlFor: 'maxArea',
            },
          },
          input: {
            trailingContent: {
              variant: 'text',
              value: 'm²',
            },
            inputElementProps: {
              name: 'maxArea',
              id: 'maxArea',
              inputMode: 'numeric',
              maxLength: 6,
            },
          },
        },
      ],
    },
  },
  {
    formElement: {
      title: {
        variant: 'label',
        value: 'Valor do condomínio',
      },
      size: 'big',
      validateRange: {
        value: true,
        group: 'maintenanceFee',
      },
    },
    filter: {
      variant: 'input',
      items: [
        {
          formElement: {
            title: {
              variant: 'label',
              value: 'Mínimo',
              htmlFor: 'minMaintenanceFee',
            },
          },
          input: {
            leadingContent: {
              variant: 'text',
              value: 'R$',
            },
            inputElementProps: {
              name: 'minMaintenanceFee',
              id: 'minMaintenanceFee',
              inputMode: 'numeric',
              maxLength: 11,
            },
          },
        },
        {
          formElement: {
            title: {
              variant: 'label',
              value: 'Máximo',
              htmlFor: 'maxMaintenanceFee',
            },
          },
          input: {
            leadingContent: {
              variant: 'text',
              value: 'R$',
            },
            inputElementProps: {
              name: 'maxMaintenanceFee',
              id: 'maxMaintenanceFee',
              inputMode: 'numeric',
              maxLength: 11,
            },
          },
        },
      ],
    },
  },
  {
    formElement: {
      title: {
        variant: 'label',
        value: 'Valor do m²',
      },
      size: 'big',
      validateRange: {
        value: true,
        group: 'pricePerArea',
      },
    },
    filter: {
      variant: 'input',
      items: [
        {
          formElement: {
            title: {
              variant: 'label',
              value: 'Mínimo',
              htmlFor: 'minPricePerArea',
            },
          },
          input: {
            leadingContent: {
              variant: 'text',
              value: 'R$',
            },
            inputElementProps: {
              name: 'minPricePerArea',
              id: 'minPricePerArea',
              inputMode: 'numeric',
              maxLength: 11,
            },
          },
        },
        {
          formElement: {
            title: {
              variant: 'label',
              value: 'Máximo',
              htmlFor: 'maxPricePerArea',
            },
          },
          input: {
            leadingContent: {
              variant: 'text',
              value: 'R$',
            },
            inputElementProps: {
              name: 'maxPricePerArea',
              id: 'maxPricePerArea',
              inputMode: 'numeric',
              maxLength: 11,
            },
          },
        },
      ],
    },
  },
  {
    formElement: {
      title: {
        variant: 'label',
        value: 'Quartos',
      },
      helpText: {
        value: 'Inclui suíte',
      },
    },
    filter: {
      variant: 'radio',
      items: [
        {
          label: '+1',
          variant: 'button',
          inputElementProps: {
            value: '1',
            name: 'minRooms',
          },
        },
        {
          label: '+2',
          variant: 'button',
          inputElementProps: {
            value: '2',
            name: 'minRooms',
          },
        },
        {
          label: '+3',
          variant: 'button',
          inputElementProps: {
            value: '3',
            name: 'minRooms',
          },
        },
        {
          label: '+4',
          variant: 'button',
          inputElementProps: {
            value: '4',
            name: 'minRooms',
          },
        },
      ],
    },
  },
  {
    formElement: {
      title: {
        variant: 'label',
        value: 'Banheiros',
      },
      helpText: {
        value: 'Inclui banheiro de suíte. Não inclui lavabo.',
        ariaDescribeId: 'Banheiros',
      },
    },
    filter: {
      variant: 'radio',
      items: [
        {
          label: '+1',
          variant: 'button',
          inputElementProps: {
            value: '1',
            name: 'minBathrooms',
          },
        },
        {
          label: '+2',
          variant: 'button',
          inputElementProps: {
            value: '2',
            name: 'minBathrooms',
          },
        },
        {
          label: '+3',
          variant: 'button',
          inputElementProps: {
            value: '3',
            name: 'minBathrooms',
          },
        },
        {
          label: '+4',
          variant: 'button',
          inputElementProps: {
            value: '4',
            name: 'minBathrooms',
          },
        },
      ],
    },
  },
  {
    formElement: {
      title: {
        variant: 'label',
        value: 'Suítes',
      },
    },
    filter: {
      variant: 'radio',
      items: [
        {
          label: '+1',
          variant: 'button',
          inputElementProps: {
            value: '1',
            name: 'minSuites',
          },
        },
        {
          label: '+2',
          variant: 'button',
          inputElementProps: {
            value: '2',
            name: 'minSuites',
          },
        },
        {
          label: '+3',
          variant: 'button',
          inputElementProps: {
            value: '3',
            name: 'minSuites',
          },
        },
        {
          label: '+4',
          variant: 'button',
          inputElementProps: {
            value: '4',
            name: 'minSuites',
          },
        },
      ],
    },
  },
  {
    formElement: {
      title: {
        variant: 'label',
        value: 'Vagas de garagem',
      },
    },
    filter: {
      variant: 'radio',
      items: [
        {
          label: 'Sem vaga',
          variant: 'button',
          inputElementProps: {
            value: '0',
            name: 'minGarageSpots',
          },
        },
        {
          label: '+1',
          variant: 'button',
          inputElementProps: {
            value: '1',
            name: 'minGarageSpots',
          },
        },
        {
          label: '+2',
          variant: 'button',
          inputElementProps: {
            value: '2',
            name: 'minGarageSpots',
          },
        },
        {
          label: '+3',
          variant: 'button',
          inputElementProps: {
            value: '3',
            name: 'minGarageSpots',
          },
        },
      ],
    },
  },
  {
    formElement: {
      title: {
        variant: 'label',
        value: 'Próximo ao metrô',
      },
      helpText: {
        value: 'Distância máxima de 1.5km',
      },
      size: 'big',
    },
    filter: {
      variant: 'radio',
      items: [
        {
          label: 'Sim',
          variant: 'button',
          inputElementProps: {
            value: '1500',
            name: 'maxSubwayDistance',
          },
        },
        {
          label: 'Tanto faz',
          variant: 'button',
          inputElementProps: {
            value: '',
            name: 'maxSubwayDistance',
          },
        },
      ],
    },
  },
  {
    formElement: {
      title: {
        variant: 'label',
        value: 'Possui elevador',
      },
      size: 'big',
    },
    filter: {
      variant: 'radio',
      items: [
        {
          label: 'Sim',
          variant: 'button',
          inputElementProps: {
            value: 'true',
            name: 'hasElevator',
          },
        },
        {
          label: 'Tanto faz',
          variant: 'button',
          inputElementProps: {
            value: '',
            name: 'hasElevator',
          },
        },
      ],
    },
  },
  {
    fullWidthDesktop: true,
    formElement: {
      title: {
        variant: 'label',
        value: 'Orientação do imóvel em relação à rua',
      },
      size: 'big',
    },
    filter: {
      variant: 'checkbox',
      items: [
        {
          label: 'Lateral',
          inputElementProps: {
            value: 'LATERAL',
            name: 'orientations',
          },
        },
        {
          label: 'Meio (dentro do condomínio)',
          inputElementProps: {
            value: 'INSIDE',
            name: 'orientations',
          },
        },
        {
          label: 'Frente',
          inputElementProps: {
            value: 'FRONTSIDE',
            name: 'orientations',
          },
        },
        {
          label: 'Fundo',
          inputElementProps: {
            value: 'BACKSIDE',
            name: 'orientations',
          },
        },
      ],
    },
  },
]

export const emptyFilters: TFieldsProps = {
  types: [],
  minPrice: '',
  maxPrice: '',
  minArea: '',
  maxArea: '',
  minBathrooms: '',
  minGarageSpots: '',
  minRooms: '',
  minSuites: '',
  orientations: [],
  sunPeriods: [],
  minPricePerArea: '',
  maxPricePerArea: '',
  minMaintenanceFee: '',
  maxMaintenanceFee: '',
  tagsSlug: [],
  maxSubwayDistance: '',
  hasElevator: '',
  isMap: false,
}
