import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconSubway: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        d="M9 16C9.55228 16 10 15.5523 10 15C10 14.4477 9.55228 14 9 14C8.44772 14 8 14.4477 8 15C8 15.5523 8.44772 16 9 16Z"
        fill={variableColor}
      />
      <path
        d="M15 16C15.5523 16 16 15.5523 16 15C16 14.4477 15.5523 14 15 14C14.4477 14 14 14.4477 14 15C14 15.5523 14.4477 16 15 16Z"
        fill={variableColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2C9.44772 2 9 2.44772 9 3C9 3.55228 9.44772 4 10 4H11V5H7C5.89543 5 5 5.89543 5 7V19H7.13166L6.16806 20.4454C5.8617 20.9049 5.98588 21.5258 6.44541 21.8322C6.90493 22.1385 7.5258 22.0143 7.83216 21.5548L9.53536 19H14.4649L16.1681 21.5548C16.4744 22.0143 17.0953 22.1385 17.5548 21.8322C18.0143 21.5258 18.1385 20.9049 17.8322 20.4454L16.8686 19H19V7C19 5.89543 18.1046 5 17 5H13V4H14C14.5523 4 15 3.55228 15 3C15 2.44772 14.5523 2 14 2H10ZM14.9995 17H17V13H7V17H9.0007C9.00092 17 9.00048 17 9.0007 17H14.9995C14.9997 17 14.9993 17 14.9995 17ZM17 8V7H7V8H17ZM7 10V11H17V10H7Z"
        fill={variableColor}
      />
    </BaseIcon>
  )
}

export default IconSubway
