import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconEye: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fill={variableColor}
        d="M12.3999 6.00415c-2.99408-.12252-6.56099 2.49302-8.66253 4.30895-.98316.8495-.98316 2.5243 0 3.3738 2.10154 1.8159 5.66845 4.4315 8.66253 4.3089 2.765-.1131 5.977-2.5995 7.8944-4.3345.9409-.8514.9409-2.4712 0-3.3226-1.9174-1.73499-5.1294-4.2214-7.8944-4.33455ZM11.9639 16.8c2.2157 0 4.0119-2.149 4.0119-4.8 0-2.65097-1.7962-4.8-4.0119-4.8-2.21577 0-4.01198 2.14903-4.01198 4.8 0 2.651 1.79621 4.8 4.01198 4.8ZM12 14c.9941 0 1.8-.8954 1.8-2s-.8059-2-1.8-2c-.9941 0-1.8.8954-1.8 2s.8059 2 1.8 2Z"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </BaseIcon>
  )
}

export default IconEye
