export const AMPLITUDE_KEY = process.env.NEXT_PUBLIC_AMPLITUDE_KEY ?? ''
export const LOG_AMPLITUDE = process.env.NEXT_PUBLIC_LOG_AMPLITUDE ?? ''
export const GTM_KEY = process.env.NEXT_PUBLIC_GTM_KEY ?? ''
export const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN ?? ''
export const SENTRY_DEBUG = process.env.NEXT_PUBLIC_SENTRY_DEBUG ?? ''
export const SENTRY_ENVIRONMENT =
  process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT ?? process.env.NODE_ENV
export const UI_VERSION = 'v1.0.0_site'
export const CLOUDINARY_WATERMARK_OVERLAY =
  'o_30,fl_relative,w_0.15,l_overlay_emcasa_zv5vve'
export const CLOUDINARY_URL = process.env
  .NEXT_PUBLIC_CLOUDINARY_IMAGE_URL as string
export const CLOUDINARY_IMAGE_URL = CLOUDINARY_URL
export const CLOUDINARY_IMAGE_URL_WITH_WATERMARK_OVERLAY = `${CLOUDINARY_URL}/${CLOUDINARY_WATERMARK_OVERLAY}`
export const GOOGLE_MAPS_API_KEY =
  process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY ?? ''
export const GOOGLE_MAPS_API_URL =
  'https://maps.googleapis.com/maps/api/staticmap'
export const GOOGLE_MAPS_GEOCODE_URL =
  'https://maps.googleapis.com/maps/api/geocode/json'
export const PAVLOV_URL = process.env.NEXT_PUBLIC_PAVLOV_URL ?? ''
export const BLOG_API_URL = process.env.NEXT_PUBLIC_BLOG_API_URL ?? ''
export const CLOUDFRONT_CITY_HEADER = 'cloudfront-viewer-city'
export const CLOUDFRONT_STATE_HEADER = 'cloudfront-viewer-country-region'
export const CLOUDFRONT_COUNTRY_HEADER = 'cloudfront-viewer-country'
export const CLOUDFRONT_X_FORWARDED_FOR_HEADER = 'x-forwarded-for'
export const LOCALSTORAGE_USER_KEY = 'userInfo'
export const LOCALSTORAGE_LOCATION_KEY = 'currentLocation'
export const DEFAULT_STATE_SLUG = 'sp'
export const DEFAULT_CITY_SLUG = 'sao-paulo'
export const APP_STORE_ID = process.env.NEXT_PUBLIC_APP_STORE_ID ?? ''
export const ZAPIER_API_URL = process.env.NEXT_PUBLIC_ZAPIER_API_URL ?? ''
