import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconElevator: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fill={variableColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.8 5.7c0-.49706.40294-.9.9-.9h12.6c.4971 0 .9.40294.9.9v12.6c0 .4971-.4029.9-.9.9H5.7c-.49706 0-.9-.4029-.9-.9V5.7ZM5.7 3C4.20883 3 3 4.20883 3 5.7v12.6C3 19.7912 4.20883 21 5.7 21h12.6c1.4912 0 2.7-1.2088 2.7-2.7V5.7C21 4.20883 19.7912 3 18.3 3H5.7Zm10.4666 7.6831c.3371 0 .641-.2177.7699-.5517.129-.3339.0577-.71829-.1806-.97388l-1.7678-1.89577C14.8319 7.09415 14.6199 7 14.3989 7c-.221 0-.433.09415-.5893.26175l-1.7677 1.89577c-.2384.25559-.3096.63998-.1807.97388.129.334.4329.5517.7699.5517h3.5355Zm-8.33325 1.6338c-.33705 0-.64091.2177-.76989.5517-.12899.3339-.05769.7183.18064.9739l1.76776 1.8957c.32544.3491.85307.3491 1.17854 0l1.7677-1.8957c.2384-.2556.3096-.64.1807-.9739-.129-.334-.4329-.5517-.7699-.5517H7.83335Z"
      />
    </BaseIcon>
  )
}

export default IconElevator
