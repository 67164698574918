import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconExclamationMark: FC<TIconProps> = ({
  color,
  width,
  height,
  title,
}) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 3c-.8284 0-1.5.67157-1.5 1.5V15c0 .8284.6716 1.5 1.5 1.5S14 15.8284 14 15V4.5c0-.82843-.6716-1.5-1.5-1.5Zm0 18c.8284 0 1.5-.6716 1.5-1.5s-.6716-1.5-1.5-1.5-1.5.6716-1.5 1.5.6716 1.5 1.5 1.5Z"
        fill={variableColor}
      />
    </BaseIcon>
  )
}

export default IconExclamationMark
