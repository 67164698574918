import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconShower: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fill={variableColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4c0-.55228.44772-1 1-1h9c.5523 0 1 .44772 1 1v2.10002c2.2822.46327 4 2.48102 4 4.89998.5523 0 1 .4477 1 1s-.4477 1-1 1H9c-.55229 0-1-.4477-1-1s.44772-1 1-1c0-2.41896 1.7178-4.43671 4-4.89998V5H6v16H4V4Zm7 7c0-1.65685 1.3431-3 3-3s3 1.34315 3 3h-6Z"
      />
      <path
        fill={variableColor}
        d="M11.5 14c-.2761 0-.5.2239-.5.5v3c0 .2761.2239.5.5.5s.5-.2239.5-.5v-3c0-.2761-.2239-.5-.5-.5Zm2.5 0c-.2761 0-.5.2239-.5.5v5c0 .2761.2239.5.5.5s.5-.2239.5-.5v-5c0-.2761-.2239-.5-.5-.5Zm2 .5c0-.2761.2239-.5.5-.5s.5.2239.5.5v3c0 .2761-.2239.5-.5.5s-.5-.2239-.5-.5v-3Z"
      />
    </BaseIcon>
  )
}

export default IconShower
