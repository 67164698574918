import amplitudeJs, { AmplitudeClient } from 'amplitude-js'
import { AMPLITUDE_KEY } from 'config'
import { User } from 'graphql/types'
import { isPWA, isTWA } from 'lib/device'
import { getAppDeviceId } from 'lib/experiment'
import { getNavigationTiming } from 'lib/performance'
import sha256 from 'sha256'

export type TUserProps = User & {
  uiVersion: string
  fcmToken: string | null
  experiments: Array<string>
}

enum EEventType {
  TYPE_IDENTIFY = 'identify',
  TYPE_EVENT = 'event',
}

export type TAmplitudeEvent = {
  type?: EEventType
  event?: string
  props?: Record<string, unknown>
  timestamp?: number
  user?: TUserProps
}

let amplitudeInstance: AmplitudeClient | null = null

export const initAmplitude = () => {
  if (
    !AMPLITUDE_KEY ||
    typeof window === 'undefined' ||
    amplitudeInstance !== null
  )
    return

  if (!window.amplitude) window.amplitude = amplitudeJs

  amplitudeInstance = amplitudeJs.getInstance()

  amplitudeInstance.init(AMPLITUDE_KEY, undefined, {
    includeReferrer: true,
    includeUtm: true,
    includeGclid: true,
    deviceId: getAppDeviceId(),
  })

  const deviceId = amplitudeInstance?.options?.deviceId

  const fbExternalId = typeof deviceId === 'string' ? sha256(deviceId) : null

  if (fbExternalId) {
    const identifyFbExternalId = new amplitudeInstance.Identify().set(
      'fbExternalId',
      fbExternalId,
    )

    amplitudeInstance.identify(identifyFbExternalId)
  }
}

export const getAmplitude = () => {
  if (amplitudeInstance === null) initAmplitude()

  return amplitudeInstance
}

const getDeviceInfo = () => {
  return { pwa: isPWA(), twa: isTWA() }
}

export const setPageLoadMetrics = () => {
  if (amplitudeInstance === null) return

  const loadMetrics = getNavigationTiming()
  const identifyLoadMetrics = new amplitudeInstance.Identify().set(
    'load',
    loadMetrics,
  )
  amplitudeInstance.identify(identifyLoadMetrics)
}

export const unsetPageLoadMetrics = () => {
  if (amplitudeInstance === null) return

  const removeLoadMetrics = new amplitudeInstance.Identify().unset('load')
  amplitudeInstance.identify(removeLoadMetrics)
}

const handleEvent = (amplitude: AmplitudeClient, event: TAmplitudeEvent) => {
  const { type, ...data } = event

  switch (type) {
    case EEventType.TYPE_IDENTIFY:
      if (data?.user?.id) amplitude.setUserId(data.user.id)
      amplitude.setUserProperties(data?.user)
      break
    case EEventType.TYPE_EVENT:
      amplitude.logEvent(data.event ?? '', data.props)
      // Cleanup page load metrics data for further events
      if (event.event === 'page-open') {
        unsetPageLoadMetrics()
      }
      break
    default:
  }
}

const pushEvent = (event: TAmplitudeEvent) => {
  const amplitude = getAmplitude()

  if (!amplitude) return

  handleEvent(amplitude, event)
}

export const identify = (userProps: TUserProps) => {
  pushEvent({
    type: EEventType.TYPE_IDENTIFY,
    user: Object.assign(userProps, getDeviceInfo()),
  })
}

export const logEvent = (
  event: TAmplitudeEvent['event'],
  props: TAmplitudeEvent['props'],
) => {
  pushEvent({
    type: EEventType.TYPE_EVENT,
    event,
    props,
  })
}
