import { LOCALSTORAGE_USER_KEY } from 'config'
import { deleteCookie, setCookie } from 'cookies-next'
import { getApolloClient } from 'graphql/apolloClient'
import { postWebviewMessage } from './webview'
import { getFutureDateFromDays } from './date'

declare global {
  interface Window {
    ReactNativeWebView: {
      postMessage(message: string): void
    }
  }
}

type TGetWhatsappUrl = {
  phone?: string
  text?: string
}

export const getWhatsappUrl = ({ phone, text }: TGetWhatsappUrl = {}) => {
  let url = 'https://wa.me'
  if (phone) url += `/${phone.replace(/[^\d]/g, '')}`
  if (text) url += `?text=${encodeURIComponent(text)}`
  return url
}

export const getFirstName = (name: string) =>
  name.substring(0, name.indexOf(' ') !== -1 ? name.indexOf(' ') : name.length)

export const clearApolloStore = async (callback?: () => void) => {
  const apolloClient = getApolloClient()
  await apolloClient
    .resetStore()
    .then(callback)
    .catch(() => {
      console.error('Apollo client reset store error')
    })
}

export const logout = async () => {
  deleteCookie('jwt')
  await clearApolloStore()

  /**
   * This is a message to the webview on RN Mobile App to logout
   * app-view-[event]
   */
  postWebviewMessage('app-webview-logout')
}

export const setJwt = (jwt: string) =>
  setCookie('jwt', jwt, { expires: getFutureDateFromDays(29) })

export const onUserLoginWebviewMessage = (jwt?: string) =>
  postWebviewMessage('app-webview-login', { jwt })

export type TUserGeoData = {
  city: string
  state: string
  country: string
  xForwardedFor: string
}

export const getStorageUserGeoData = (): TUserGeoData =>
  JSON.parse(
    localStorage?.getItem(LOCALSTORAGE_USER_KEY) || '{}',
  ) as TUserGeoData
