import { AnchorHTMLAttributes, ButtonHTMLAttributes, FC } from 'react'
import Link from 'next/link'
import classNames from 'classnames'
import { TIconComponent } from 'icons/types'
import { TAnchorElementProps } from './types'
import { splitAnchorLinkProps } from './utils'
import styles from './Anchor.module.scss'

type TButtonAttributes = ButtonHTMLAttributes<HTMLButtonElement>
type TAnchorAttributes = AnchorHTMLAttributes<HTMLAnchorElement>

type TConditionalProps =
  | ({ element?: 'a' } & TAnchorAttributes)
  | ({ element?: 'button' } & TButtonAttributes)

export type TProps = {
  Icon?: Omit<TIconComponent, 'width' | 'height' | 'color'>
} & TConditionalProps

const Anchor: FC<TProps> = ({
  element = 'a',
  children,
  Icon,
  className,
  ...rest
}) => {
  const anchorClasses = classNames(className, styles.ecAnchor, {
    [styles.ecAnchorHasIcon]: Icon,
  })

  if (element === 'a') {
    const { linkProps, anchorProps } = splitAnchorLinkProps({
      ...(rest as TAnchorElementProps),
      className: anchorClasses,
    })

    return (
      <Link {...linkProps} {...anchorProps}>
        {Icon && <Icon.Component color="pink500" title={Icon.title} />}
        {children}
      </Link>
    )
  }

  const buttonProps = {
    ...(rest as TButtonAttributes),
    className: anchorClasses,
  }

  return (
    <button type="button" {...buttonProps}>
      {Icon && <Icon.Component color="pink500" title={Icon.title} />}
      {children}
    </button>
  )
}

export default Anchor
