declare global {
  interface Navigator {
    standalone?: boolean
  }
}

export const isTWA = () => {
  if (typeof window === 'undefined') return false

  return (
    document &&
    document.referrer &&
    document.referrer.includes('android-app://com.emcasa.twa')
  )
}

export const isPWA = () => {
  if (typeof window === 'undefined') return false

  return (
    ('navigator' in window && window.navigator.standalone) ||
    ('matchMedia' in window &&
      window.matchMedia('(display-mode: standalone)').matches)
  )
}

export const isCookieEnabled = () => {
  if (typeof window === 'undefined') return false

  return navigator?.cookieEnabled
}

export function isApp() {
  return isTWA()
}

export const isIOS = () => {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
  ].includes(navigator.platform)
}

export const isIframe = () => {
  try {
    return typeof window !== 'undefined' && window.self !== window.parent
  } catch (e) {
    return true
  }
}
