import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconHouseCircleMoneySign: FC<TIconProps> = ({
  color,
  width,
  height,
  title,
}) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fill={variableColor}
        fillRule="evenodd"
        d="M3 10.0729c0-.66869.3342-1.29315.8906-1.66408l7-4.66667c.6718-.44787 1.547-.44787 2.2188 0l7 4.66667c.5564.37093.8906.99539.8906 1.66408v8.9296c0 1.1046-.8954 2-2 2H5c-1.10457 0-2-.8954-2-2v-8.9296Zm11 8.9296v-4h-4v4H5v-8.9296l7-4.66665 7 4.66665v8.9296h-5Z"
        clipRule="evenodd"
      />
      <circle cx="6" cy="18" r="5.5" fill={variableColor} stroke="#fff" />
      <path
        fill="#fff"
        d="M5.778 21.3c-.04667 0-.08633-.0163-.119-.049-.03267-.0327-.049-.0723-.049-.119v-.49c-.34533-.0327-.637-.1143-.875-.245-.238-.1353-.41767-.2963-.539-.483-.12133-.1913-.18667-.3897-.196-.595 0-.042.014-.077.042-.105.03267-.028.07-.042.112-.042h.623c.056 0 .10033.014.133.042.03267.0233.05833.0537.077.091.03267.0887.08633.1773.161.266.07933.084.189.154.329.21.14.056.315.084.525.084.336 0 .58567-.056.749-.168.16333-.112.245-.2637.245-.455 0-.1353-.04433-.2427-.133-.322-.084-.084-.21933-.1587-.406-.224-.18667-.07-.43167-.1423-.735-.217-.34533-.084-.637-.1843-.875-.301-.238-.1213-.41767-.273-.539-.455-.11667-.182-.175-.413-.175-.693 0-.3593.13067-.665.392-.917s.623-.406 1.085-.462v-.483c0-.0467.01633-.0863.049-.119.03267-.0327.07233-.049.119-.049h.427c.04667 0 .08633.0163.119.049.03267.0327.049.0723.049.119v.497c.31733.0467.58333.14.798.28.21467.14.378.301.49.483.112.1773.17033.35.175.518 0 .0373-.014.0723-.042.105-.02333.028-.05833.042-.105.042h-.651c-.042 0-.08167-.0093-.119-.028-.03733-.0187-.06767-.0537-.091-.105-.02333-.1447-.112-.2683-.266-.371-.154-.1027-.35467-.154-.602-.154-.252 0-.455.0467-.609.14-.14933.0933-.224.238-.224.434 0 .1307.03733.2403.112.329.07467.084.196.1587.364.224.17267.0653.40133.1353.686.21.39667.0887.71867.1913.966.308.252.1167.43633.266.553.448.12133.1773.182.4037.182.679 0 .2707-.06767.5087-.203.714-.13067.2007-.31733.364-.56.49-.24267.1213-.52733.2007-.854.238v.483c0 .0467-.01633.0863-.049.119-.03267.0327-.07233.049-.119.049h-.427Z"
      />
    </BaseIcon>
  )
}

export default IconHouseCircleMoneySign
