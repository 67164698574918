import { setContext } from '@apollo/client/link/context'
import { NEXT_DATA } from 'next/dist/shared/lib/utils'
import { NextApiRequestCookies } from 'next/dist/server/api-utils'
import { getCookie } from 'cookies-next'
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  NormalizedCacheObject,
} from '@apollo/client'
import fetch from 'cross-fetch'
import { UI_VERSION } from 'config'
import { getAppVersion } from 'lib/experiment'

type TAuthLinkContextOptions = {
  headers?: Record<string, string>
}

type TGetApolloClientOptions = {
  forceNew?: boolean
  cookies?: NextApiRequestCookies
}

const isServer = typeof window === 'undefined'

let apolloClient: ApolloClient<NormalizedCacheObject>

const authLink = (jwtCookie?: string) =>
  setContext((_, context: TAuthLinkContextOptions) => {
    const token = jwtCookie || (getCookie('jwt') as string)

    return {
      headers: {
        ...context.headers,
        ...(isServer && {
          'User-Agent': `site - ${getAppVersion() || UI_VERSION}`,
        }),
        authorization: token ? `Token ${token}` : '',
      },
    }
  })

const httpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_API_URL,
  credentials: 'same-origin',
  fetch,
})

export function getApolloClient({
  forceNew = false,
  cookies = {},
}: TGetApolloClientOptions = {}) {
  if (!forceNew && apolloClient) return apolloClient

  const windowApolloState =
    !isServer &&
    // eslint-disable-next-line no-underscore-dangle
    (window.__NEXT_DATA__ as NEXT_DATA & { apolloState: NormalizedCacheObject })
      ?.apolloState

  apolloClient = new ApolloClient({
    ssrMode: isServer,
    link: authLink(cookies?.jwt).concat(httpLink),
    cache: new InMemoryCache().restore(windowApolloState || {}),
  })

  return apolloClient
}
