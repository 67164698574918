import { FC, useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import Loader from 'components/Loader'
import Button, { TProps as ButtonProps } from 'components/Button'
import { IconArrowLeft, IconSearch } from 'icons'
import styles from './BackButton.module.scss'

export const BACKBUTTON_SESSIONSTORAGE = 'EmCasaBackButtonSessionStorage'

type TProps = {
  href: string
  isIframe?: boolean
  onClick?: () => void
}
const BackButton: FC<TProps> = ({ href, isIframe, onClick }) => {
  const router = useRouter()
  const [showSearchButton, setShowSearchButton] = useState<boolean | undefined>(
    undefined,
  )
  const getLeadingButtonProps = (): Partial<ButtonProps> => {
    if (isIframe) {
      const label = 'Fechar imóvel'
      return {
        element: 'button',
        'aria-label': label,
        title: label,
        Icon: {
          Component: IconArrowLeft,
          title: label,
          color: 'grey900',
        },
        onClick: () => window.parent.postMessage({ id: 'closeIframe' }, '*'),
      }
    }

    const label = showSearchButton
      ? 'Buscar mais imóveis'
      : 'Voltar para a página anterior'

    return {
      element: showSearchButton ? 'a' : 'button',
      prefetch: showSearchButton ? false : undefined,
      'aria-label': label,
      title: label,
      Icon: {
        Component: showSearchButton ? IconSearch : IconArrowLeft,
        title: label,
        color: 'grey900',
      },
      onClick: () => {
        if (onClick) onClick()
        if (!showSearchButton) {
          void router.back()
        }
      },
      href: showSearchButton && href ? href : undefined,
    }
  }

  useEffect(() => {
    setShowSearchButton(!sessionStorage.getItem(BACKBUTTON_SESSIONSTORAGE))
  }, [])

  return showSearchButton === undefined ? (
    <div className={styles.ecBackButtonLoaderWrapper}>
      <Loader size={24} />
    </div>
  ) : (
    <Button
      shape="circle"
      theme="white"
      size="small"
      {...getLeadingButtonProps()}
    />
  )
}

export default BackButton
