import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconMap: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 3L15 5L19.6838 3.43874C20.3313 3.2229 21 3.70487 21 4.38743V18.2792C21 18.7097 20.7246 19.0918 20.3162 19.2279L15 21L9 19L4.31623 20.5613C3.6687 20.7771 3 20.2951 3 19.6126V5.72076C3 5.29033 3.27543 4.90819 3.68377 4.77208L9 3ZM14 18.5585V6.77485L10 5.44152V17.2251L14 18.5585ZM8 17.2251V5.44152L5 6.44152V18.2251L8 17.2251ZM19 17.5585L16 18.5585V6.77485L19 5.77485V17.5585Z"
        fill={variableColor}
      />
    </BaseIcon>
  )
}

export default IconMap
