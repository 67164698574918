import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import mapValues from 'lodash/mapValues'
import omitBy from 'lodash/omitBy'
import set from 'lodash/set'
import { ParsedUrlQuery } from 'querystring'
import { Suggestion } from 'use-places-autocomplete'
import {
  Address,
  City,
  Collection,
  Condominium,
  District,
  GetSearchContextQuery,
  ListingFilterInput,
  ListingSearchOrderBy,
  ListingSearchPagination,
  OrientationType,
  Point,
  SearchListingsQueryVariables,
  SunPeriodType,
  Tag,
} from 'graphql/types'
import { CLOUDINARY_URL, DEFAULT_CITY_SLUG, DEFAULT_STATE_SLUG } from 'config'
import { orderCitiesByVolume } from 'lib/cities'
import { IconCondominium, IconPin } from 'icons'
import { emptyFilters } from 'components/FiltersModal/filters'
import { TFieldsProps } from 'components/FiltersModal/types'
import { parseLocation } from './location'
import { toCurrencyNoStyle, toValidNumber } from './number'

type TVariables = {
  filters?: ListingFilterInput
  pagination: ListingSearchPagination
  orderBy?: ListingSearchOrderBy
  isMap?: boolean
  collection?: string
  pointOfInterest?: string
  condominium?: string
}

export type TQueryParams = {
  stateSlug: string
  citiesSlug: string
  lc?: string
  pagination: number
  neighborhoods?: string
  streetSlug?: string
  filters?: string
  isMap?: boolean
  collection?: string
  pointOfInterest?: string
  orderBy?: string
  condominium?: string
} & TFieldsProps

export type TypedUrlQueries = ParsedUrlQuery & TQueryParams
type TValue =
  | Record<string, unknown>
  | string[]
  | string
  | number
  | boolean
  | undefined

type TFiltersProperties = {
  queryString: string
  isUrlSegment?: (value?: string) => boolean | string
  variablesPath: string
  related?: keyof TQueryParams
  formatToVar?: (args: TValue) => TValue
  formatToFilter?: (args: TValue) => TValue
  formatToQueryString?: (value: string, relatedValue?: string) => string
  parseFromUrl?: (
    params: ParsedUrlQuery,
    {
      districts,
      tags,
      searchCollections,
      pointsOfInterest,
    }: {
      districts?: Array<District>
      tags?: Array<Tag>
      searchCollections?: Array<Collection>
      pointsOfInterest?: Array<Point>
      condominiums?: Array<Condominium>
    },
  ) => TValue | null
}

type TGetRangeValues = {
  value: TValue
  relatedValue?: TValue
  keyString: string
  minRangeString: string
}

export type TToParseRange = {
  fieldValue?: TValue
  params: ParsedUrlQuery
  rangeRegex: RegExp
  regex?: RegExp
  type?: 'min' | 'max'
}

export type TToParseValue = {
  params: string
  regex: RegExp
  defaultValue?: string
}

type TTypeaheadOptions = {
  value?: string
  cities: Array<City>
  suggestions: Suggestion[]
}

export const SunPeriods = new Map<SunPeriodType, string>([
  [SunPeriodType.Evening, 'tarde'],
  [SunPeriodType.Morning, 'manha'],
])

export const Orientations = new Map<OrientationType, string>([
  [OrientationType.Backside, 'fundo'],
  [OrientationType.Frontside, 'frente'],
  [OrientationType.Inside, 'meio'],
  [OrientationType.Lateral, 'lateral'],
])

export const PAGE_SIZE = 12
export const RECOMMENDATIONS_BANNER_SIZE = 2
export const RADIUS = 1500
export const DEFAULT_PAGINATION: ListingSearchPagination = {
  from: 0,
  size: PAGE_SIZE,
}
export const DEFAULT_QUERY_VARIABLES = {
  pagination: 1,
  stateSlug: DEFAULT_STATE_SLUG,
  citiesSlug: DEFAULT_CITY_SLUG,
  neighborhoods: '',
  streetSlug: '',
  lc: '',
  ...emptyFilters,
}
export const BRAZIL_CENTER = { lat: -10.3333333, lng: -53.2 }
export const createBounds = (coordinates: google.maps.LatLngLiteral) => ({
  north: coordinates.lat + 0.1,
  south: coordinates.lat - 0.1,
  east: coordinates.lng + 0.1,
  west: coordinates.lng - 0.1,
})
export const getPlacesOptions = ({
  lat,
  lng,
}: google.maps.LatLngLiteral = BRAZIL_CENTER) => ({
  requestOptions: {
    bounds: createBounds({ lat, lng }),
    componentRestrictions: {
      country: 'br',
    },
    types: ['geocode'],
  },
  initOnMount: false,
})
const STREET_PREFIX_MAPPING = [
  'rua',
  'avenida',
  'alameda',
  'praia',
  'praca',
  'estrada',
  'travessa',
  'largo',
  'viaduto',
  'av',
]
const TYPE_MAPPING = ['Apartamento', 'Casa', 'Cobertura']
const FILTERS_MAPPING_ORDERED = [
  'stateSlug',
  'citiesSlug',
  'neighborhoods',
  'condominium',
  'streetSlug',
  'types',
  'pointOfInterest',
  'collection',
  'tagsSlug',
  'minRooms',
  'minGarageSpots',
  'minBathrooms',
  'maxSubwayDistance',
  'isMap',
]
const IGNORE_FIELDS_MAPPING = [
  'stateSlug',
  'citiesSlug',
  'streetSlug',
  'neighborhoods',
  'lc',
  'pagination',
  'collection',
  'pointOfInterest',
  'orderBy',
  'condominium',
]

export const SEARCH_ORDER_MAPPING = [
  {
    optionLabel: 'Menor preço',
    optionValue: 'menor-preco',
    orderBy: {
      field: 'PRICE',
      type: 'ASC',
    },
  },
  {
    optionLabel: 'Maior preço',
    optionValue: 'maior-preco',
    orderBy: {
      field: 'PRICE',
      type: 'DESC',
    },
  },
]

export const SUBWAY_DISTANCE = 1500
export const MIN_PRICE_REGEX = /preco-min-(\d+)/
export const MAX_PRICE_REGEX = /preco-max-(\d+)/
export const MIN_AREA_REGEX = /area-min-(\d+)/
export const MAX_AREA_REGEX = /area-max-(\d+)/
export const MIN_M2_REGEX = /precoM2Minimo-(\d+)/
export const MAX_M2_REGEX = /precoM2Maximo-(\d+)/
export const MIN_MAINTENANCE_REGEX = /condominio-min-(\d+)/
export const MAX_MAINTENANCE_REGEX = /condominio-max-(\d+)/
export const MIN_MAX_PRICE_REGEX = /de-(\d+)-ate-(\d+)/
export const MIN_MAX_AREA_REGEX = /areaDe-(\d+)-ate-(\d+)/
export const MIN_MAX_M2_REGEX = /precoM2De-(\d+)-ate-(\d+)/
export const MIN_MAX_MAINTENANCE_REGEX = /condominioDe-(\d+)-ate-(\d+)/
export const MIN_ROOMS_REGEX = /(\d+)-quartos/
export const MIN_GARAGE_SPOTS_REGEX = /(\d+)-vagas/
export const MIN_BATHROOMS_REGEX = /(\d+)-banheiros/
export const metaImage = `${CLOUDINARY_URL}/share/share-3`
export const MIN_PRICE = 400000
export const MAX_PRICE = 5000000
export const COLLECTION_NAME_DEFAULT = 'Todos os imóveis'

export const transformSearchCollections = ({
  collections,
  currentCollection,
  onClick,
}: {
  currentCollection?: string
  collections?: GetSearchContextQuery['searchCollections']
  onClick: (collection?: Collection | null) => void
}) => {
  const DEFAULT_COLLECTIONS = [
    {
      element: 'button',
      active: !currentCollection,
      activeKey: 'todos-os-imoveis',
      label: COLLECTION_NAME_DEFAULT,
      Icon: {
        Component: IconCondominium,
        title: 'Ícone: Imóveis',
      },
      onClick: () => onClick({ name: COLLECTION_NAME_DEFAULT }),
    },
  ]

  if (!collections || collections?.length === 0) return [...DEFAULT_COLLECTIONS]

  return [
    ...DEFAULT_COLLECTIONS,
    ...collections
      .map((collection) => ({
        order: collection?.order,
        element: 'button',
        active: currentCollection === collection?.nameSlug,
        activeKey: collection?.nameSlug as string,
        label: collection?.name as string,
        image: {
          src: collection?.image || '/img/photos/collection-default.svg',
          alt: collection?.name as string,
          priority: true,
        },
        onClick: () => onClick(collection),
      }))
      .sort((a, b) => (a.order as number) - (b.order as number)),
  ]
}

export const collectionTitle: Record<string, string> = {
  'abaixo-do-mercado': 'à venda com preço abaixo do mercado',
  'baixou-preco': 'à venda com redução de preço',
  'imoveis-de-luxo': 'à venda de luxo',
  'mais-vistos': 'à venda mais vistos no site',
  mobiliado: 'à venda com mobília',
  'novo-na-emcasa': 'à venda adicionados recentemente na EmCasa',
  'para-criancas': 'à venda para morar com criança',
  'treinar-em-casa': 'à venda ideais para treinar',
  'varanda-incrivel': 'à venda com varandas incríveis',
}

export const joinWordCollections: Array<string> = [
  'abaixo-do-mercado',
  'baixou-preco',
  'mobiliado',
  'varanda-incrivel',
]

const getPointOfInterest = (hasSubway: boolean, pointOfInterest: string) => {
  if (!hasSubway && !pointOfInterest) return null

  if (hasSubway && pointOfInterest) {
    return `próximos ao metrô e ao ${pointOfInterest}`
  }
  if (hasSubway) return 'próximos ao metrô'

  return `próximos ao ${pointOfInterest}`
}

export const getListingAddress = (address: Address, includeState = false) => {
  const { street, neighborhood, city, state } = address

  const stateString = includeState ? ` - ${state!}` : ''

  if (!neighborhood && !street) {
    return `${city!}${stateString}`
  }
  if (!street) {
    return `${neighborhood!}${stateString}`
  }
  return `${street}, ${neighborhood!}${stateString}`
}

export const getTitle = ({
  lastSelectedTag,
  queryVariables,
  collection,
  pointOfInterest,
  tags,
  condominium,
}: {
  lastSelectedTag?: string | null
  queryVariables: TQueryParams
  collection?: Collection
  pointOfInterest?: Point
  tags?: Array<Tag>
  condominium?: Condominium
}) => {
  const { types, tagsSlug, minRooms, maxSubwayDistance, minGarageSpots } =
    queryVariables || {}

  const hasTag = tagsSlug && tagsSlug.length > 0
  const hasOneType = types?.length === 1

  let title = 'Imóveis $collection'

  if (minRooms) {
    title = `Imóveis $collection com ${minRooms} ou mais quartos`
  }

  if (minGarageSpots) {
    title = `Imóveis $collection com ${minGarageSpots} ou mais vagas de garagem`
  }

  if (hasTag) {
    const firstTag = tags?.find(({ nameSlug }) => {
      return lastSelectedTag
        ? nameSlug === lastSelectedTag
        : nameSlug === tagsSlug[tagsSlug.length - 1]
    })
    const tagName = firstTag?.name?.toLocaleLowerCase() || ''

    title = `Imóveis $collection com ${tagName}`
  }

  const points = getPointOfInterest(
    !!maxSubwayDistance,
    pointOfInterest?.name as string,
  )
  if (points) title = `${title} ${points}`

  if (condominium) {
    title = `${title} próximos ao ${condominium.name as string}`
  }

  if (hasOneType) title = title.replace('Imóveis', `${types[0]}s`)

  if (collection) {
    title = title.replace(
      '$collection',
      `${collectionTitle[collection?.nameSlug as string]}`,
    )
  }

  title = title.replace('$collection', 'à venda')

  return title
}

export const getCanonicalURL = ({
  lastSelectedTag,
  queryVariables,
  collection,
  tags,
  address,
  pointOfInterest,
}: {
  lastSelectedTag?: string | null
  queryVariables: TQueryParams
  collection?: Collection
  tags?: Array<Tag>
  address: Partial<Address>
  pointOfInterest?: Point
}) => {
  const {
    types,
    tagsSlug,
    minRooms,
    maxSubwayDistance,
    minGarageSpots,
    condominium,
  } = queryVariables

  const hasTag = tagsSlug && tagsSlug.length > 0
  const hasOneType = types && types?.length === 1
  const neighborhoodSlug = address.neighborhoodSlug
    ? `/${address.neighborhoodSlug}`
    : ''

  let baseUrl = `/imoveis/${address.stateSlug as string}/${
    address.citySlug as string
  }${neighborhoodSlug}`

  let canonicalUrl = baseUrl

  /**
   * Type and collection are fixed properties (if filtered) and should be place before any other property
   * The priority of appeareance is: condominium > type > pointOfInterest > collection > tagsSlug > nearbySubway > minGarageSpots > minRooms
   **/
  if (condominium) {
    baseUrl = canonicalUrl = `${canonicalUrl}/${condominium}`
  }
  if (hasOneType) {
    baseUrl = canonicalUrl = `${baseUrl}/${types[0].toLowerCase()}`
  }
  if (pointOfInterest) {
    baseUrl = canonicalUrl = `${canonicalUrl}/${
      pointOfInterest.nameSlug as string
    }`
  }
  if (collection) {
    baseUrl = canonicalUrl = `${canonicalUrl}/${collection?.nameSlug as string}`
  }

  if (minRooms) canonicalUrl = `${baseUrl}/${minRooms}-quartos`
  if (minGarageSpots) canonicalUrl = `${baseUrl}/${minGarageSpots}-vagas`
  if (maxSubwayDistance) canonicalUrl = `${baseUrl}/proximo-metro`
  if (hasTag) {
    const firstTag = tags?.find(({ nameSlug }) => {
      return lastSelectedTag
        ? nameSlug === lastSelectedTag
        : nameSlug === tagsSlug[tagsSlug.length - 1]
    })
    canonicalUrl = `${baseUrl}/${firstTag?.nameSlug as string}`
  }

  return canonicalUrl
}

export const getDescription = ({
  city,
  street,
  neighborhood,
  tags,
  lastSelectedTag,
  ...queryVariables
}: {
  city: string
  neighborhood?: string
  street?: string
  tags?: Array<Tag>
  lastSelectedTag?: string
} & Partial<TQueryParams>) => {
  const {
    types,
    minRooms,
    minGarageSpots,
    maxSubwayDistance,
    tagsSlug,
    collection,
    pointOfInterest,
    condominium,
  } = queryVariables

  const hasTag = tagsSlug && tagsSlug.length > 0
  const listingsType =
    types?.length === 1 ? `${types[0].toLowerCase()}s` : 'apartamentos e casas'
  const descriptionStart = `Encontramos o imóvel ideal para você! Confira ${listingsType} $collection`
  const descriptionEnd =
    ' $condominium em $location. EmCasa, todos os imóveis com os melhores corretores.'

  let listingsDescription = `${descriptionStart}${descriptionEnd}`
  const joinWord =
    collection && joinWordCollections.includes(collection) ? 'e' : 'com'

  if (minRooms) {
    listingsDescription = `${descriptionStart} ${joinWord} ${minRooms} ou mais quartos${descriptionEnd}`
  }

  if (minGarageSpots) {
    listingsDescription = `${descriptionStart} ${joinWord} ${minGarageSpots} ou mais vagas de garagem${descriptionEnd}`
  }

  const points = getPointOfInterest(
    !!maxSubwayDistance,
    pointOfInterest as string,
  )
  if (points)
    listingsDescription = `${descriptionStart} ${points}${descriptionEnd}`

  if (hasTag) {
    const firstTag = tags?.find(({ nameSlug }) => {
      return lastSelectedTag
        ? nameSlug === lastSelectedTag
        : nameSlug === tagsSlug[tagsSlug.length - 1]
    })
    listingsDescription = `${descriptionStart} ${joinWord} ${
      firstTag?.name?.toLocaleLowerCase() || ''
    }${descriptionEnd}`
  }

  if (collection) {
    listingsDescription = listingsDescription.replace(
      '$collection',
      `${collectionTitle[collection]}`,
    )
  } else {
    listingsDescription = listingsDescription.replace(
      ' $collection',
      ' à venda',
    )
  }

  if (condominium) {
    listingsDescription = listingsDescription.replace(
      '$condominium',
      `no ${condominium}`,
    )
  } else {
    listingsDescription = listingsDescription.replace(' $condominium', '')
  }

  if (street)
    return listingsDescription.replace('$location', `${street}, ${city}`)

  if (neighborhood)
    return listingsDescription.replace('$location', `${neighborhood}, ${city}`)

  return listingsDescription.replace('$location', city)
}

export const getFullAddress = ({
  city,
  neighborhood,
  street,
}: Partial<Address>): string =>
  [street, neighborhood, city].filter(Boolean).join(', ')

export const toValueMapType = (
  value: TValue,
  map: Map<SunPeriodType | OrientationType, string>,
) => {
  if (Array.isArray(value)) return value
  return Array.from(map)
    .filter(
      ([entryKey, entryValue]) =>
        (value as string).split(',').includes(entryValue) || value === entryKey,
    )
    .map(([key]) => key)
}

export const getTypeaheadOptions = ({
  value,
  suggestions,
  cities,
}: TTypeaheadOptions) => {
  if (value) {
    return suggestions.map((sugg) => ({
      label: sugg.description,
      value: sugg.description,
      Icon: {
        Component: IconPin,
      },
    }))
  } else {
    const orderedCities = orderCitiesByVolume(cities)
    return orderedCities?.map((item) => ({
      label: item?.name || '',
      value: `${item?.stateSlug || ''}/${item?.nameSlug || ''}`,
      Icon: {
        Component: IconPin,
      },
    }))
  }
}

export const toCommaArray = (value: TValue): Array<string> =>
  Array.isArray(value) ? value : (value as string).split(',')

export const toNumber = (value: TValue) =>
  typeof value === 'number' ? value : (toValidNumber(value as string) as TValue)

export const getRangeValues = ({
  value,
  relatedValue,
  keyString,
  minRangeString,
}: TGetRangeValues) => {
  const formatValue = toNumber(value)
  if (!relatedValue) {
    return `${keyString}=${formatValue as string}`
  }
  const formatRelated = toNumber(relatedValue)
  return `${minRangeString}-${formatValue as string}-ate-${
    formatRelated as string
  }`
}

export const toParseRange = ({
  fieldValue,
  params,
  regex,
  rangeRegex,
  type,
}: TToParseRange) => {
  if (fieldValue) return fieldValue
  if (regex && params.filters) {
    const segments = (params.filters as string).split('/')
    const matchRegex = segments.find((seg) => regex.test(seg))
    if (matchRegex) {
      const [, value] = matchRegex?.match(regex) || []
      return toNumber(value)
    }
  }

  const matchRegex = Object.keys(params).find((key) => rangeRegex.test(key))
  if (matchRegex) {
    const [, minPrice, maxPrice] = matchRegex?.match(rangeRegex) || []
    return type === 'min' ? toNumber(minPrice) : toNumber(maxPrice)
  }
  return undefined
}

export const toParseValue = ({
  params,
  regex,
  defaultValue = '',
}: TToParseValue) => {
  if (!params) return defaultValue
  const segments = params.split('/')
  const matchRegex = segments.find((seg) => regex.test(seg))
  if (matchRegex) {
    const [, value] = matchRegex?.match(regex) || []
    return value
  }
  return defaultValue
}

export const Filters: Record<string, TFiltersProperties> = {
  isMap: {
    queryString: 'isMap',
    variablesPath: 'isMap',
    formatToQueryString: () => '',
    isUrlSegment: (value) => {
      return value ? 'mapa' : false
    },
    parseFromUrl: (params) => {
      if (params.isMap) return true
      if (!params.filters) return false

      const segments = (params.filters as string).split('/')
      const isMap = segments.includes('mapa')
      return isMap
    },
  },
  pagination: {
    queryString: 'pagina',
    variablesPath: 'pagination',
    formatToVar: (page) => {
      const pageNumber = Number(page) || 1
      const BANNER_PAGE = 1
      const isBannerPage = pageNumber === BANNER_PAGE

      return {
        from: isBannerPage
          ? 0
          : (pageNumber - 1) * PAGE_SIZE - RECOMMENDATIONS_BANNER_SIZE,
        size: isBannerPage
          ? PAGE_SIZE - RECOMMENDATIONS_BANNER_SIZE
          : PAGE_SIZE,
      }
    },
    parseFromUrl: (params) => params.page ?? 1,
    formatToFilter: toNumber,
  },
  orderBy: {
    queryString: 'ordenar',
    variablesPath: 'orderBy',
  },
  collection: {
    queryString: 'collection',
    variablesPath: 'collection',
    isUrlSegment: () => true,
    parseFromUrl: (params, { searchCollections }) => {
      if (params.collection) return params.collection
      if (!params.filters) return undefined

      const collectionURL = (params.filters as string).split('/')
      const hasCollection = searchCollections?.find((collection) =>
        collectionURL.includes(collection.nameSlug as string),
      )
      return hasCollection?.nameSlug as string
    },
  },
  pointOfInterest: {
    queryString: 'pointOfInterest',
    variablesPath: 'filters.pointOfInterestSlug',
    isUrlSegment: () => true,
    parseFromUrl: (params, { pointsOfInterest }) => {
      if (params.pointOfInterest) return params.pointOfInterest
      if (!params.filters) return undefined

      const currentUrl = (params.filters as string).split('/')
      const hasPointOfInterest = pointsOfInterest?.find((point) =>
        currentUrl.includes(point.nameSlug as string),
      )
      return hasPointOfInterest?.nameSlug as string
    },
  },
  stateSlug: {
    queryString: 'state',
    isUrlSegment: () => true,
    formatToVar: toCommaArray,
    variablesPath: 'filters.locationAddress.statesSlug',
  },
  citiesSlug: {
    queryString: 'city',
    isUrlSegment: () => true,
    formatToVar: toCommaArray,
    variablesPath: 'filters.locationAddress.citiesSlug',
  },
  neighborhoods: {
    queryString: 'neighborhoods',
    formatToVar: toCommaArray,
    variablesPath: 'filters.locationAddress.neighborhoodsSlugs',
    isUrlSegment: () => true,
    formatToQueryString: () => '',
    parseFromUrl: (params, { districts }) => {
      if (!params.filters) return ''

      const segments = (params.filters as string).split('/')
      const isCoveredDistrict = districts?.find(
        (d) => d.nameSlug && segments.includes(d.nameSlug),
      )
      return isCoveredDistrict?.nameSlug || ''
    },
  },
  condominium: {
    queryString: 'condominium',
    variablesPath: 'condominium',
    isUrlSegment: () => true,
    parseFromUrl: (params, { condominiums }) => {
      if (params.condominium) return params.condominium
      if (!params.filters) return undefined

      const currentUrl = (params.filters as string).split('/')
      const hasCondominium = condominiums?.find((condominium) =>
        currentUrl.includes(condominium.nameSlug as string),
      )
      return hasCondominium?.nameSlug as string
    },
  },
  streetSlug: {
    queryString: 'streetSlug',
    variablesPath: 'filters.locationAddress.streetSlug',
    isUrlSegment: () => true,
    formatToQueryString: () => '',
    parseFromUrl: (params, { districts }) => {
      if (!params.filters) return ''

      const segments = (params.filters as string).split('/')
      const isCoveredDistrict = districts?.find(
        (d) => d.nameSlug && segments.includes(d.nameSlug),
      )

      const regexp = new RegExp(`^(${STREET_PREFIX_MAPPING.join('|')}-*)`)
      const isStreet = segments.find((s) => s.match(regexp))
      return (isCoveredDistrict && isStreet) || ''
    },
  },
  lc: {
    queryString: 'lc',
    variablesPath: 'filters.location',
    formatToVar: (value) => {
      const { lng, lat } = parseLocation(value as string)

      return {
        center: JSON.stringify({
          type: 'Point',
          coordinates: [lng, lat],
        }),
        radius: RADIUS,
      }
    },
  },
  types: {
    queryString: 'tipo',
    variablesPath: 'filters.types',
    isUrlSegment: (value) => {
      const types = (value as string).split(',')
      if (types.length <= 1) {
        return (value as string).toLowerCase()
      }
      return false
    },
    formatToFilter: toCommaArray,
    formatToVar: toCommaArray,
    parseFromUrl: (params) => {
      if (params.types?.length) return params.types
      if (!params.filters) return ''

      const types = (params.filters as string)
        .split('/')
        .map((filter) => filter.toLowerCase())
      const isType = TYPE_MAPPING.find((typeMap) =>
        types.includes(typeMap.toLowerCase()),
      )

      return isType
    },
  },
  minGarageSpots: {
    queryString: 'garagem',
    variablesPath: 'filters.minGarageSpots',
    isUrlSegment: (value) => {
      return `${value as string}-vagas`
    },
    parseFromUrl: (params) =>
      toParseValue({
        params: params?.filters as string,
        regex: MIN_GARAGE_SPOTS_REGEX,
      }),
    formatToVar: toNumber,
  },
  minRooms: {
    queryString: 'quartos',
    formatToVar: toNumber,
    variablesPath: 'filters.minRooms',
    isUrlSegment: (value) => {
      return `${value as string}-quartos`
    },
    parseFromUrl: (params) =>
      toParseValue({
        params: params?.filters as string,
        regex: MIN_ROOMS_REGEX,
      }),
  },
  minPrice: {
    queryString: 'precoMinimo',
    formatToVar: toNumber,
    variablesPath: 'filters.minPrice',
    related: 'maxPrice',
    formatToQueryString: (value, relatedValue) =>
      getRangeValues({
        value,
        relatedValue,
        keyString: 'precoMinimo',
        minRangeString: 'de',
      }),
    formatToFilter: (minPrice) => toCurrencyNoStyle(Number(minPrice)),
    parseFromUrl: (params) =>
      toParseRange({
        fieldValue: params.minPrice,
        params,
        regex: MIN_PRICE_REGEX,
        rangeRegex: MIN_MAX_PRICE_REGEX,
        type: 'min',
      }),
  },
  maxPrice: {
    queryString: 'precoMaximo',
    formatToVar: toNumber,
    variablesPath: 'filters.maxPrice',
    related: 'minPrice',
    formatToQueryString: (value, relatedValue) =>
      relatedValue ? '' : `precoMaximo=${toNumber(value) as string}`,
    formatToFilter: (maxPrice) => toCurrencyNoStyle(Number(maxPrice)),
    parseFromUrl: (params) =>
      toParseRange({
        fieldValue: params.maxPrice,
        params,
        regex: MAX_PRICE_REGEX,
        rangeRegex: MIN_MAX_PRICE_REGEX,
        type: 'max',
      }),
  },
  minArea: {
    queryString: 'areaMinima',
    formatToVar: toNumber,
    variablesPath: 'filters.minArea',
    related: 'maxArea',
    formatToQueryString: (value, relatedValue) =>
      getRangeValues({
        value,
        relatedValue,
        keyString: 'areaMinima',
        minRangeString: 'areaDe',
      }),
    parseFromUrl: (params) =>
      toParseRange({
        fieldValue: params.minArea,
        params,
        regex: MIN_AREA_REGEX,
        rangeRegex: MIN_MAX_AREA_REGEX,
        type: 'min',
      }),
  },
  maxArea: {
    queryString: 'areaMaxima',
    variablesPath: 'filters.maxArea',
    formatToVar: toNumber,
    related: 'minArea',
    formatToQueryString: (value, relatedValue) =>
      relatedValue ? '' : `areaMaxima=${value}`,
    parseFromUrl: (params) =>
      toParseRange({
        fieldValue: params.maxArea,
        params,
        regex: MAX_AREA_REGEX,
        rangeRegex: MIN_MAX_AREA_REGEX,
        type: 'max',
      }),
  },
  minMaintenanceFee: {
    queryString: 'condominioMinimo',
    formatToVar: toNumber,
    variablesPath: 'filters.minMaintenanceFee',
    related: 'maxMaintenanceFee',
    formatToFilter: (minMaintenanceFee) =>
      toCurrencyNoStyle(Number(minMaintenanceFee)),
    formatToQueryString: (value, relatedValue) =>
      getRangeValues({
        value,
        relatedValue,
        keyString: 'condominioMinimo',
        minRangeString: 'condominioDe',
      }),
    parseFromUrl: (params) =>
      toParseRange({
        fieldValue: params.minMaintenanceFee,
        params,
        regex: MIN_MAINTENANCE_REGEX,
        rangeRegex: MIN_MAX_MAINTENANCE_REGEX,
        type: 'min',
      }),
  },
  maxMaintenanceFee: {
    queryString: 'condominioMaximo',
    formatToVar: toNumber,
    variablesPath: 'filters.maxMaintenanceFee',
    related: 'minMaintenanceFee',
    formatToFilter: (maxMaintenanceFee) =>
      toCurrencyNoStyle(Number(maxMaintenanceFee)),
    formatToQueryString: (value, relatedValue) =>
      relatedValue ? '' : `condominioMaximo=${toNumber(value) as string}`,
    parseFromUrl: (params) =>
      toParseRange({
        fieldValue: params.maxMaintenanceFee,
        params,
        regex: MAX_MAINTENANCE_REGEX,
        rangeRegex: MIN_MAX_MAINTENANCE_REGEX,
        type: 'max',
      }),
  },
  minPricePerArea: {
    queryString: 'precoM2Minimo',
    formatToVar: toNumber,
    variablesPath: 'filters.minPricePerArea',
    related: 'maxPricePerArea',
    formatToFilter: (minPricePerArea) =>
      toCurrencyNoStyle(Number(minPricePerArea)),
    formatToQueryString: (value, relatedValue) =>
      getRangeValues({
        value,
        relatedValue,
        keyString: 'precoM2Minimo',
        minRangeString: 'precoM2De',
      }),
    parseFromUrl: (params) =>
      toParseRange({
        fieldValue: params.minPricePerArea,
        params,
        regex: MIN_M2_REGEX,
        rangeRegex: MIN_MAX_M2_REGEX,
        type: 'min',
      }),
  },
  maxPricePerArea: {
    queryString: 'precoM2Maximo',
    formatToVar: toNumber,
    variablesPath: 'filters.maxPricePerArea',
    related: 'minPricePerArea',
    formatToFilter: (maxPricePerArea) =>
      toCurrencyNoStyle(Number(maxPricePerArea)),
    formatToQueryString: (value, relatedValue) =>
      relatedValue ? '' : `precoM2Maximo=${toNumber(value) as string}`,
    parseFromUrl: (params) =>
      toParseRange({
        fieldValue: params.maxPricePerArea,
        params,
        regex: MAX_M2_REGEX,
        rangeRegex: MIN_MAX_M2_REGEX,
        type: 'max',
      }),
  },
  minBathrooms: {
    queryString: 'banheiro',
    formatToVar: toNumber,
    variablesPath: 'filters.minBathrooms',
    isUrlSegment: (value) => {
      return `${value as string}-banheiros`
    },
    parseFromUrl: (params) =>
      toParseValue({
        params: params?.filters as string,
        regex: MIN_BATHROOMS_REGEX,
      }),
  },
  minSuites: {
    queryString: 'suites',
    formatToVar: toNumber,
    variablesPath: 'filters.minSuites',
  },
  tagsSlug: {
    queryString: 'caracteristicas',
    variablesPath: 'filters.tagsSlug',
    formatToFilter: toCommaArray,
    formatToVar: toCommaArray,
    isUrlSegment: (values) => (values as string).split(',').length <= 1,
    parseFromUrl: (params, { tags }) => {
      if (params.tagsSlug?.length) return params.tagsSlug
      if (!params.filters) return undefined

      const tagURL = (params.filters as string).split('/')
      const hasTag = tags?.find((tag) =>
        tagURL.includes(tag.nameSlug as string),
      )
      return hasTag?.nameSlug as string
    },
  },
  sunPeriods: {
    queryString: 'sol',
    variablesPath: 'filters.sunPeriods',
    formatToQueryString: (value) => {
      const multipleValues = value.split(',')
      const sunPeriods = multipleValues
        .map((key) => SunPeriods.get(key as SunPeriodType))
        .join(',')
      return `sol=${sunPeriods}`
    },
    formatToVar: (value) => toValueMapType(value, SunPeriods),
    formatToFilter: (value) => toValueMapType(value, SunPeriods),
  },
  orientations: {
    queryString: 'orientacao',
    variablesPath: 'filters.orientations',
    formatToQueryString: (value) => {
      const multipleValues = value.split(',')
      const orientations = multipleValues
        .map((key) => Orientations.get(key as OrientationType))
        .join(',')
      return `orientacao=${orientations}`
    },
    formatToVar: (value) => toValueMapType(value, Orientations),
    formatToFilter: (value) => toValueMapType(value, Orientations),
  },
  maxSubwayDistance: {
    queryString: 'proximo-metro',
    variablesPath: 'filters.maxSubwayDistance',
    isUrlSegment: () => 'proximo-metro',
    parseFromUrl: (params) => {
      if (!params.filters) return ''
      const segments = (params.filters as string).split('/')
      if (segments.includes('proximo-metro')) {
        return SUBWAY_DISTANCE
      }
      return ''
    },
    formatToVar: toNumber,
    formatToFilter: String.bind(this),
  },
  hasElevator: {
    queryString: 'possui-elevador',
    variablesPath: 'filters.hasElevator',
    formatToQueryString: () => 'possui-elevador',
    parseFromUrl: (params) => 'possui-elevador' in params,
    formatToVar: Boolean.bind(this),
    formatToFilter: String.bind(this),
  },
}

export const getAppliedFilters = ({
  queryParams,
  normalize,
}: {
  queryParams: TQueryParams
  normalize?: boolean
}) => {
  const appliedFilters = omitBy(queryParams, (value) => {
    return (
      value === '' ||
      isNil(value) ||
      (typeof value === 'object' && isEmpty(value))
    )
  })

  if (!normalize) return appliedFilters

  const normalizeFilters = mapValues(appliedFilters, (filter) => {
    if (Array.isArray(filter)) return encodeURI(filter.join(','))

    return filter
  })

  return normalizeFilters
}

export const getRouterProperties = (queryParams: ParsedUrlQuery) => {
  const filtered = getAppliedFilters({
    queryParams: queryParams as unknown as TQueryParams,
    normalize: true,
  })
  const unorderedParams: Array<Array<string>> = []
  const search: Array<string> = []

  Object.entries(filtered).forEach(([key, value]) => {
    const { queryString, related, formatToQueryString, isUrlSegment } =
      Filters[key] || {}

    const relatedValue = related && filtered[related]
    const formattedParam = formatToQueryString
      ? formatToQueryString(value as string, relatedValue as string)
      : `${queryString}=${value as string}`

    const urlSegment = isUrlSegment?.(value as string)
    if (FILTERS_MAPPING_ORDERED.includes(key) && urlSegment) {
      unorderedParams.push([
        key,
        typeof urlSegment === 'boolean' ? (value as string) : urlSegment,
      ])
    } else {
      search.push(formattedParam)
    }
  })

  const params = unorderedParams
    .sort(([keyA], [keyB]) => {
      const indexA = FILTERS_MAPPING_ORDERED.indexOf(keyA)
      const indexB = FILTERS_MAPPING_ORDERED.indexOf(keyB)
      return indexA - indexB
    })
    .map(([, value]) => value)

  return {
    pathname: `/imoveis/${params.join('/')}`,
    search: search.filter(Boolean).join('&'),
  }
}

export const getVariablesProperties = ({
  queryParams,
  districts,
  tags,
  searchCollections,
  pointsOfInterest,
  condominiums,
}: {
  queryParams: ParsedUrlQuery
  districts?: Array<District>
  tags?: Array<Tag>
  searchCollections?: Array<Collection>
  pointsOfInterest?: Array<Point>
  condominiums?: Array<Condominium>
}) => {
  const searchVariables: SearchListingsQueryVariables = {}
  const filterVariables = {}

  Object.entries(Filters).forEach(([key, properties]) => {
    const {
      variablesPath,
      formatToVar,
      formatToFilter,
      parseFromUrl,
      queryString,
    } = properties

    const filter = queryParams[key] || queryParams[queryString]
    const parsedValue = parseFromUrl?.(queryParams, {
      districts,
      tags,
      searchCollections,
      pointsOfInterest,
      condominiums,
    })
    let variableValue: TValue
    let filterValue: TValue

    if (filter && key) {
      variableValue = formatToVar?.(filter) ?? filter
      filterValue = formatToFilter?.(filter) ?? filter
    } else if (parsedValue) {
      variableValue = formatToVar?.(parsedValue) ?? parsedValue
      filterValue = formatToFilter?.(parsedValue) ?? parsedValue
    }

    const filterDefaultValue = emptyFilters[key as keyof TFieldsProps] ?? ''
    set(filterVariables, key, filterValue || filterDefaultValue)

    if (variableValue) set(searchVariables, variablesPath, variableValue)
  })

  const hasLocation = Boolean(searchVariables.filters?.location)
  const hasOrderBy = Boolean(searchVariables.orderBy)
  const orderBy = SEARCH_ORDER_MAPPING.find(
    (item) => item.optionValue === searchVariables.orderBy,
  )
  const ordering = [
    hasOrderBy && orderBy?.orderBy,
    hasLocation && {
      field: 'DISTANCE',
      type: 'ASC',
    },
    {
      field: 'STAT_LISTING_COUNT',
      type: 'DESC',
    },
  ].filter(Boolean)

  set(searchVariables, 'orderBy', ordering)
  set(searchVariables, 'filters.statuses', ['active'])

  return {
    searchVariables: searchVariables as TVariables,
    filterVariables: filterVariables as TQueryParams,
  }
}

export const getFiltersCount = (fields?: TQueryParams) => {
  let applied = 0
  if (!fields) return applied

  Object.entries(fields).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      applied += value.length
    } else if (value && !IGNORE_FIELDS_MAPPING.includes(key)) applied += 1
  })
  return applied
}

export const getAveragePrice = (price: number, percentage = 15) => {
  const percentageValue = (percentage / 100) * price

  return {
    minimum: Number((price - percentageValue).toFixed(0)),
    maximum: Number((price + percentageValue).toFixed(0)),
  }
}

export const toValidEventLogPrices = (
  filters?: Pick<
    TQueryParams,
    | 'maxMaintenanceFee'
    | 'maxPrice'
    | 'maxPricePerArea'
    | 'minMaintenanceFee'
    | 'minPricePerArea'
    | 'minPrice'
  >,
) => {
  return {
    ...filters,
    maxMaintenanceFee: toValidNumber(filters?.maxMaintenanceFee || ''),
    maxPrice: toValidNumber(filters?.maxPrice || ''),
    maxPricePerArea: toValidNumber(filters?.maxPricePerArea || ''),
    minMaintenanceFee: toValidNumber(filters?.minMaintenanceFee || ''),
    minPricePerArea: toValidNumber(filters?.minPricePerArea || ''),
    minPrice: toValidNumber(filters?.minPrice || ''),
  }
}
