import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconSecurity: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fill={variableColor}
        d="M11.1997 3.16429c.5111-.21905 1.0897-.21905 1.6008 0l6.0229 2.58125c.8078.34621 1.3374 1.19616 1.1968 2.12232-.2639 1.73784-.8676 4.15424-1.9473 6.51424-1.0758 2.3513-2.6627 4.7308-4.9394 6.2846-.7112.4853-1.6484.433-2.3036-.1069-2.04766-1.6875-3.56738-4.0427-4.65492-6.3343-1.09134-2.2997-1.77943-4.60303-2.13137-6.25905-.20413-.96052.32845-1.87604 1.17759-2.23996l5.9785-2.5622ZM6.04014 7.58618c.3266 1.52088.96564 3.65062 1.97053 5.76812 1.00562 2.119 2.34663 4.1601 4.04843 5.5851 1.8207-1.2694 3.1864-3.2613 4.1661-5.4027.9796-2.1412 1.5356-4.35023 1.7796-5.93134l-6.0047-2.57344-5.95996 2.55426Z"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </BaseIcon>
  )
}

export default IconSecurity
