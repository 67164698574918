import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconTrafficCone: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fill={variableColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8377 4.25724C13.1672 2.58093 10.7943 2.58091 10.1237 4.25723L4.19275 19.0851H3C2.44772 19.0851 2 19.5328 2 20.0851C2 20.6374 2.44772 21.0851 3 21.0851H4.8465C4.86185 21.0855 4.87718 21.0855 4.89247 21.0851H19.0689C19.0842 21.0855 19.0996 21.0855 19.1149 21.0851L20.7778 21.0851C21.3301 21.0851 21.7778 20.6374 21.7778 20.0851C21.7778 19.5328 21.3301 19.0851 20.7778 19.0851L19.7687 19.0851L13.8377 4.25724ZM17.6146 19.0851L16.6368 16.6406H7.32458L6.34681 19.0851H17.6146ZM14.3806 11L15.8369 14.6406H8.12456L9.58077 11H14.3806ZM11.9807 5L13.5807 9H10.3808L11.9807 5Z"
      />
    </BaseIcon>
  )
}

export default IconTrafficCone
