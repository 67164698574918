import sha256 from 'sha256'
import { initAmplitude } from 'lib/logger/amplitude'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import {
  getActiveExperiments,
  getActiveExperimentsList,
  getAppVersion,
} from 'lib/experiment'
import { isIframe } from 'lib/device'
import { User, UserProfileQuery } from 'graphql/types'
import GET_USER_PROFILE from 'graphql/queries/userProfile'
import { UI_VERSION, LOG_AMPLITUDE } from 'config'
import * as amplitude from './amplitude'
import * as GTM from './gtm'
import * as sentry from './sentry'
import * as Pavlov from './pavlov'

type TActionEvent = string
type TActionProps = GTM.TGTMEvent['props'] | amplitude.TAmplitudeEvent['props']

type TLogger = {
  capture: (error: sentry.TSentryLog['error'], customName?: string) => void
  action: (event: TActionEvent, props?: TActionProps) => void
  identify: (user?: User | null, fcmToken?: string | null) => void
  init: (apolloClient: ApolloClient<NormalizedCacheObject>) => void
}

let currentUser: User | null = null
let currentFcmToken: string | null = null
let subscribed = false
let pavlovEventsQueue: Array<() => void> = []

const getDefaultEventProps = () => ({
  ...getActiveExperiments(),
  experiments: getActiveExperimentsList(),
  uiVersion: getAppVersion() || UI_VERSION,
})

const generateEventID = () =>
  crypto.getRandomValues(new Uint32Array(1))[0].toString()

const logger: TLogger = {
  capture(error, customName) {
    const errorClause = error as Error
    if (customName) {
      errorClause.name = customName
    }
    sentry.capture({ error: errorClause })

    return error
  },
  action(event, props) {
    const eventProps: TActionProps = Object.assign(props || {}, {
      ...getDefaultEventProps(),
      eventID: generateEventID(),
      path: window && window.location ? window.location.pathname : null,
      windowScrollY: window && window.scrollY ? window.scrollY : null,
      isIframe: isIframe(),
    })

    sentry.log(event, { category: 'action', level: 'info', ...props })

    if (LOG_AMPLITUDE === 'true') {
      console.log(
        `%c${event}`,
        'color:white; background-color: #990f46; border-radius: 4px; padding: 2px 8px;',
        eventProps,
      )
    }

    if (currentUser?.role === 'admin') return

    amplitude.logEvent(event, eventProps)
    GTM.logEvent({ event, props: eventProps })

    if (Pavlov.PAVLOV_EVENTS[event]) {
      if (!subscribed)
        pavlovEventsQueue.push(() =>
          Pavlov.logEvent({ event, props: eventProps, user: currentUser }),
        )
      else Pavlov.logEvent({ event, props: eventProps, user: currentUser })
    }
  },
  identify(user = null, fcmToken = null) {
    if (user !== null) currentUser = user
    if (fcmToken !== null) currentFcmToken = fcmToken

    const userSha256Props = {
      sha256_email: user?.email ? sha256(user?.email) : '',
      sha256_phone_number: user?.phone ? sha256(user?.phone) : '',
    }

    const userProps = {
      ...(user || currentUser || {}),
      ...getDefaultEventProps(),
      ...userSha256Props,
      fcmToken: currentFcmToken,
    }

    sentry.identify(userProps)
    amplitude.identify(userProps)
    GTM.identify(userProps)
  },
  init(apolloClient) {
    initAmplitude()

    apolloClient
      .watchQuery<UserProfileQuery>({
        query: GET_USER_PROFILE,
        fetchPolicy: 'cache-first',
        errorPolicy: 'ignore',
      })
      .subscribe(({ data }) => {
        if (data?.userProfile) {
          logger.identify(data.userProfile)
        }

        subscribed = true

        if (pavlovEventsQueue.length) {
          pavlovEventsQueue.forEach((fn) => fn())
          pavlovEventsQueue = []
        }
      })

    this.identify()
  },
}

export default logger
