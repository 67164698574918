import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconBed: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 10c0 .0875.00561.1736.0165.2581C3.40961 10.6015 3 11.2529 3 12v7c0 .5523.44772 1 1 1s1-.4477 1-1v-2h14v2c0 .5523.4477 1 1 1s1-.4477 1-1v-7c0-.7471-.4096-1.3985-1.0165-1.7419.0109-.0845.0165-.1706.0165-.2581V6c0-1.10457-.8954-2-2-2H6c-1.10457 0-2 .89543-2 2v4Zm14-4H6v4h1V9c0-.55228.44772-1 1-1h2c.5523 0 1 .44772 1 1v1h2V9c0-.55228.4477-1 1-1h2c.5523 0 1 .44772 1 1v1h1V6Zm-2 4V9h-2v1h2Zm-6 0V9H8v1h2Zm9 2v3H5v-3h14Z"
        fill={variableColor}
      />
    </BaseIcon>
  )
}

export default IconBed
