import { FC } from 'react'
import { getVariableValue } from 'lib/styles'
import { TIconProps } from 'icons/types'
import BaseIcon from 'icons/BaseIcon'

const IconWhatsApp: FC<TIconProps> = ({ color, width, height, title }) => {
  const variableColor = getVariableValue(color)

  return (
    <BaseIcon width={width} height={height} viewBox="0 0 24 24" title={title}>
      <path
        fill={variableColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5983 13.6897c-.1952-.107-1.164-.6191-1.345-.6879-.181-.0726-.3124-.1069-.4437.107-.1313.214-.5075.6878-.6246.833-.1135.1414-.2307.1605-.4259.0535-1.1569-.6228-1.9164-1.1119-2.6794-2.5219-.11234-.2079-.0376-.2922.1156-.4646.1226-.1382.2956-.3329.4629-.6932.0638-.1414.0319-.2637-.0178-.37065-.035-.07539-.2409-.61558-.4166-1.0765-.0737-.19322-.142-.37252-.1902-.4978-.14038-.36263-.28345-.36236-.39796-.36214-.01581.00003-.03107.00006-.04571-.00087C9.47637 8 9.34505 8 9.21374 8c-.13131 0-.34425.0535-.52524.26366-.01191.01407-.02523.02944-.03972.04616C8.44292 8.54731 8 9.05829 8 10.0328c0 1.0161.67005 1.9996.79339 2.1806.00329.0048.00619.0091.00867.0128.00684.0097.0193.0289.03723.0565.24266.3735 1.48641 2.288 3.32721 3.1456 1.2492.5808 1.739.6304 2.3636.5311.3797-.0611 1.164-.512 1.3273-1.0088.1632-.4967.1632-.9209.1136-1.0088-.0386-.0798-.1368-.1303-.282-.2051-.0285-.0146-.0587-.0302-.0907-.047Z"
      />
      <path
        fill={variableColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21c4.9706 0 9-4.0294 9-9 0-4.97056-4.0294-9-9-9-4.97056 0-9 4.02944-9 9 0 1.4734.35404 2.864.98168 4.0916l-.68751 3.4375c-.13994.6997.47698 1.3167 1.1767 1.1767l3.43755-.6875C9.13597 20.646 10.5266 21 12 21Zm-5.9206-5.199-.31698-.6199C5.27554 14.2289 5 13.1495 5 12c0-3.86599 3.13401-7 7-7 3.866 0 7 3.13401 7 7 0 3.866-3.134 7-7 7-1.1495 0-2.22886-.2755-3.18109-.7624l-.61995-.317-2.64945.5299.52989-2.6495Z"
      />
    </BaseIcon>
  )
}

export default IconWhatsApp
